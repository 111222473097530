
import { useContext, useState, useEffect } from "react";
import {
    Button,
    Grid,
    Paper,
} from "@material-ui/core";

import { format, max, min } from 'date-fns'
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import userService from "../../../services/userService";

import AddIcon from "@material-ui/icons/Add";
import Functions from "@material-ui/icons/Functions";
import productionMgmtService from "../../../services/productionMgmtService";
import { AddProductionRecord } from "./productionRecordForm2"
import { filter } from "ag-charts-community/dist/cjs/es5/scale/continuousScale";
import { DefectIncidentProduction } from "../../qualityAnalytics/defectClassification/defectIncidentProductionAdd"

import ProductionRecordSummary from "./production_record_summary"


const ProductionRecordTable = () => {
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { globalPlant } = useContext(PlantContext);
    const [plants, setPlants] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [rows, setRows] = useState([])
    const [formOpen, setFormOpen] = useState(false)
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [maxID, setMaxID] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    const [qincidentFormOpen, setQIncidentFormOpen] = useState(false);


    const [recordSummaryForm, setRecordSummaryForm] = useState(false)


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
            callback: changeStatus,
        }));
        reloadGridData()
    }, []);

    useEffect(() => {
        reloadGridData()
    }, [globalPlant])

    useEffect(() => {

        setHeaderPageTitle(`Production Record`)

    }, []);

    function reloadGridData() {

        if (!globalPlant) {
            return;
        }

        productionMgmtService.getProductionRecordTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                const data = res?.data ?? []
                setRows(data);
                setMaxID(data.reduce((max, item) => item.record_counter > max ? item.record_counter : max, data[0].record_counter))
            });


    }


    function onRowSelection(data) {
        setSelectedRow(data[0]);
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            flex: 1,
            resizable: true,
            minWidth: 50,
        },
        {
            field: "record_counter",
            headerName: "Record Number",
            flex: 1,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <>
                        {'PREC/' + params?.data?.record_counter}
                    </>
                );
            }
        },

        {
            field: "part_name",
            headerName: "Part Number",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.part_number}-{params?.data?.part_name}
                    </>
                )
            },
        }
        ,
        {
            field: "machine_name",
            headerName: "Machine",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "operator_name",
            headerName: "Operator ID",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            sortable: true,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.operator_name}-{params?.data?.operator_eid}
                    </>
                )
            },
        },

        {
            field: "production_date",
            headerName: "Date:Production",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            sort: "desc",
            sortable: true,
            valueGetter: (params) => {
                // Return the Date object for sorting
                return new Date(params?.data?.production_date);
            },
            valueFormatter: (params) => {
                // Format the date for display
                return format(new Date(params.value), 'dd/MM/yyyy');
            }
        }
        ,

        {
            field: "production_start_time",
            headerName: "Start Time:Production",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,

            valueGetter: (params) => {
                const productionStartTime = params?.data?.production_start_time;
                if (productionStartTime) {
                    const [hours, minutes] = productionStartTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },
        {
            field: "production_end_time",
            headerName: " End Time : Production",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            valueGetter: (params) => {
                const productionEndTime = params?.data?.production_end_time;
                if (productionEndTime) {
                    const [hours, minutes] = productionEndTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },
        {
            field: "production_quantity",
            headerName: "Production Quantity",
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            minWidth: 150,
        },
        {
            field: "production_rejections",
            headerName: "Rejection Quantity",
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            minWidth: 150,
        },



        {
            field: "tool_shots",
            headerName: "Production Date",
            flex: 1,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 200,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },

            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,

                            entityName: `${params?.data?.machine_number ?? ""
                                }  ${params?.data?.machine_name ?? ""}`,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "history",
            headerName: "Status History",
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params.data.id,
                                entity: params?.data?.name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return productionMgmtService.updateProductionRecord(rowId, { enabled: newStatus });
    }


    function ButtonComponent() {
        return (
            <>

                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setFormOpen(true);
                    }}
                    style={{ marginLeft: 3 }}
                >
                    Production
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Functions />}
                    onClick={() => {
                        setRecordSummaryForm(true);
                    }}
                    style={{ marginLeft: 3 }}
                >
                    Summary
                </Button>

                {selectedRow && (
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setQIncidentFormOpen(true);
                        }}
                        style={{ marginLeft: 3 }}
                    >
                        Q Incident
                    </Button>
                )}
            </>
        );
    }



    return (
        <Paper>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <CommonGrid
                        coldefs={{
                            filter: true,
                            filterParams: {
                                apply: true,
                                newRowsAction: "keep",
                                enabled: { filter: 1 },
                            },
                        }}

                        columns={columns}
                        rows={rows}
                        ButtonComponent={ButtonComponent}
                        gridheight={100}
                        onRowSelect={onRowSelection}

                    />
                </Grid>
            </Grid>

            <DGDrawer
                needSave
                drawerWidth={1200}
                isOpen={formOpen}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData();
                }}
                Component={AddProductionRecord}

                title="Record Production"
                componentProps={{ maxID: maxID }}
            />

            <DGDrawer
                needSave
                drawerWidth={900}
                isOpen={qincidentFormOpen}
                setOpen={(state) => {
                    setQIncidentFormOpen(false);
                    reloadGridData();
                }}
                Component={DefectIncidentProduction}
                title="Record Q Incident"
                componentProps={selectedRow}


            />


            <DGDrawer
                needSave
                drawerWidth={1200}
                isOpen={recordSummaryForm}
                setOpen={(state) => {
                    setRecordSummaryForm(false);
                }}
                Component={ProductionRecordSummary}
                title="Production Record Summary"

            />



        </Paper>
    );


}
export default ProductionRecordTable