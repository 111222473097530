import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from '../../../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import StatusFilter from "../../../../components/shared/StatusFilter";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import machineMaintenanceService from '../../../../services/machineMaintenanceService';
import { DGDrawer } from '../../../../components/DGDrawer';
import AddIcon from '@material-ui/icons/Add';
import { MachineMaintenanceCLPQAdd } from "./machineMaintenanceCLPQAdd"
import { MachineMaintenanceCLPQView } from "./machineMaintenanceCLPQView"
import { PlantContext } from '../../../../context/PlantContextProvider';


export function MachineMaintenanceCheckList({

}) {

    const { setStatus } = useContext(StatusContext);
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const [viewFormOpen, setViewFormOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const { globalPlant } = useContext(PlantContext);
    const [maxID, setMaxID] = useState(0);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
        }));
        reloadGridData();

    }, []);

    useEffect(() => {
        reloadGridData();
    }, [globalPlant]);

    function reloadGridData() {
        return machineMaintenanceService.getMachineMaintenanceCLP(
            currentUser.client_id,
            globalPlant?.globalPlant?.id)
            .then((res) => {
                setRows(res?.data ?? []);
                const data = res?.data ?? []
                setMaxID(data.reduce((max, item) => item.plan_number > max ? item.plan_number : max, data[0].plan_number))

            });
    }


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return machineMaintenanceService.UpdateMachineMentananceCLP(rowId, { enabled: newStatus });
    }


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },


        {
            field: "plan_number",
            headerName: "Plan Number",
            minWidth: 300,
            sorting: true,
            resizable: true,

            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadForm(data)
                        }}
                    >
                        {`${params?.data?.plan_number + " / " + params?.data?.plan_name}`}
                    </Button>
                );
            },
        },

        {
            field: "machine_name",
            headerName: "Machine",
            minWidth: 200,
            resizable: true,
            sorting: true,
            cellRenderer: (params) => {
                if (params.data.machine_number) {
                    return `${params?.data?.machine_number} / ${params?.data?.machine_name}`
                } else {
                    return ""

                }

            }
        },


        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.plan_number + " / " + params?.data?.plan_name,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.q_number ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        }
    ]


    function loadForm(data) {
        setSelectedRow(data);
        setViewFormOpen(true);

    }

    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryFormOpen(true);
                    }}
                >
                    Create Maintenance Plan
                </Button>

            </>
        );
    }

    return (

        <>
            <Grid item md={12} sm={12} lg={12}>

                <CommonGrid
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    columns={columns}
                    gridheight={115}

                />

            </Grid>

            <DGDrawer
                isOpen={viewFormOpen}
                setOpen={() => {
                    setViewFormOpen(false)
                }}
                Component={MachineMaintenanceCLPQView}
                drawerWidth={800}
                needSave={false}
                componentProps={selectedRow}
            />

            <DGDrawer
                isOpen={entryFormOpen}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()
                }}
                title="Create Maintenance Plan"
                Component={MachineMaintenanceCLPQAdd}
                drawerWidth={800}
                needSave
                componentProps={{ maxID: maxID }}

            />
        </>

    )
}
