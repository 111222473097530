import { useEffect, useContext, useState, useCallback } from "react";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { Grid, Button, AppBar, Divider, Toolbar } from "@material-ui/core";
import { DGInput } from "../../../../components/shared/DGInput";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { format } from "date-fns";
import { AlertContext } from "../../../../context/AlertContextProvider";


export default function MachineMaintenanaceRequestView({
    closeForm = () => { },
    Footer = () => { },
    componentProps = null
}) {

    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const [recordFlag, setRecordFlag] = useState(false)
    const [evaluateFlaf, setEvaluateFlag] = useState(false)
    const [resultStatus, setResultStatus] = useState(0)
    const [statusQ] = useState([
        { value: 201, label: "OK" },
        { value: 202, label: "Opportunity for Improvement" },
        { value: 203, label: "Not OK" }
    ]);
    const [evaluationDate, setEvaluationDate] = useState(new Date(componentProps?.evaluation_date));

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    useEffect(() => {
        machineMaintenanceService.getMachineMAintenanceCLPRes(componentProps?.id)
            .then((res) => {
                setRows(res.data)

            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    useEffect(() => {
        recordFlag1()
    }, [rows])


    function evaluateResult() {
        if (rows.length > 0) {
            const len = rows.length
            const len1 = rows.filter(obj => obj.q_result === 201).length;
            const len2 = rows.filter(obj => obj.q_result === 202).length;
            const len3 = rows.filter(obj => obj.q_result === 203).length;


            if (len3 > 0) {
                setResultStatus(0)
                var result = 0
            }
            else if (len2 > 0) {
                setResultStatus(2)
                var result = 2
            }
            else {
                setResultStatus(1)
                var result = 1
            }
        }
        return result
    }



    function recordFlag1() {
        if (rows.length !== 0) {
            const count = rows.filter(obj => obj.q_result === null).length;
            if (count === 0) {
                setRecordFlag(true)
            }
            else {
                setRecordFlag(false)
            }
        }
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 50,
        },
        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 200,
        },
        {
            field: "q_description",
            headerName: "Description",
            filter: "agTextColumnFilter",
            minWidth: 400,
            cellRenderer: (params) => {
                const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                return wrappedText;
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3' // 
            },
            autoHeight: true,
        },




        {
            field: "q_result",
            headerName: "Result",
            minWidth: 200,
            cellEditor: "agRichSelectCellEditor",
            cellEditorPopup: true,
            editable: true,
            cellEditorParams: {
                values: statusQ.map(option => option.label),
                cellHeight: 20,
            },
            valueFormatter: (params) => {
                const match = statusQ.find(option => option.value === params.value);
                return match ? match.label : params.value;
            },
            valueSetter: (params) => {
                const match = statusQ.find(option => option.label === params.newValue);
                params.data.q_result = match ? match.value : params.newValue;
                return true;
            },
        }
        ,

        {
            field: "comments",
            headerName: "Comments",
            filter: "agTextColumnFilter",
            minWidth: 400,
            editable: true,
        },


    ]


    async function onEvaluate() {
        gridApi.forEachNode((node) => {
            if (node.data.q_result === null) { return }

            const node_temp = {
                comments: node.data.comments,
                q_result: node.data.q_result,
                updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }
            machineMaintenanceService.updateMachineMaintenanceCLPRes(node.data.id, node_temp)
        })
        await recordFlag1()

        updateAlert({
            open: true,
            message: "Maintenance Check List Evaluated",
            type: "success",
        });
    }


    function onRecordRequest() {
        onEvaluate()
        onRecordRequest_()
    }


    async function onRecordRequest_() {
        // await evaluateResult()


        const temp1 = evaluateResult()


        const payload = {
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            request_result: temp1,
            evaluation_date: format(evaluationDate, 'yyyy-MM-dd HH:mm:ss')
        }

        machineMaintenanceService.updateMachineMaintenanceCLPReq(componentProps.id, payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    open: true,
                    message: "Recorded Maintenance Check List",
                    type: "success",
                });
            })
            .catch(() => {
                updateAlert({
                    open: true,
                    message: "Failed to record Checklist ",
                    type: "error",
                });
            });

    }

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <DGInput
                        label="Machine "
                        value={componentProps?.machine_number + "/" + componentProps?.machine_name}
                    />


                </Grid>

                <Grid item xs={6}>
                    <DGInput
                        label="Maintenance Plan "
                        value={componentProps?.plan_number + "/" + componentProps?.plan_name}
                    />

                </Grid>

                <Grid item xs={12}>
                    <DGInput
                        label="Comments"
                        value={componentProps?.comments}
                    />


                </Grid>


                <Grid item xs={12}>

                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={50}
                        onGridReady={onGridReady}
                    />
                </Grid>

            </Grid>

            <Grid item xs={12} >
                <AppBar position="fixed" style={{ width: 1000, top: "auto", bottom: 0 }}>
                    <Divider />


                    <Toolbar>
                        <Grid item xs={6}
                            style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <DGDateInput
                                id="evaluationDate"
                                label="Evaluation Date"
                                value={evaluationDate}
                                format="dd/MM/yyyy"
                                onChange={(date) => setEvaluationDate(date)}
                                isFormik
                            />
                        </Grid>

                        <Grid container justifyContent="flex-end">
                            <Button
                                style={{ margin: 4 }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => closeForm()}
                            >
                                CANCEL
                            </Button>
                            <Button
                                style={{ margin: 4 }}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={componentProps?.request_result}
                                onClick={() => onEvaluate()}
                            >
                                Evaluate Request
                            </Button>
                            <Button
                                style={{ margin: 4 }}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={!recordFlag || componentProps?.request_result}
                                onClick={() => onRecordRequest()}
                            >
                                Record Request
                            </Button>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
        </>
    )

}