import { Heading } from "@chakra-ui/react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";

export function GembaCard({
  index = -1,
  name = "",
  onClick = () => {},
  color = "#fff",
}) {
  return (
    <Grid
      item
      md={4}
      spacing={0}
      justifyContent="center"
      className="text-center"
      onClick={() => onClick(index)}
      alignContent="center"
      style={{ height: "100%", width: "100%", cursor: "pointer" }}
    >
      <Card style={{ backgroundColor: color }}>
        <CardContent style={{ height: "200px" }}>
          <Heading as="h3" size="lg" style={{ color: "#fff" }}>
            {name}
          </Heading>
        </CardContent>
      </Card>
    </Grid>
  );
}
