import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { Button, Grid } from "@material-ui/core";
import { AuthContext } from "../../../../context/AuthContextProvider";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";


export function MaintenanceCheckPointAdd({
    Footer = () => { },
    closeForm = () => { },
    componentProps = null
}) {

    const [rows, setRows] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require('uuid');
    const { currentUser } = useContext(AuthContext);


    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function removeDetailRow(index, rowData) {
        let data = rows;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
    }

    const generateBlankRows = (numRows) => {
        return Array.from({ length: numRows }, () => ({}));
    };

    useEffect(() => {
        setRows(generateBlankRows(10));
    }, []);

    function addRow(numRows) {
        const newRows = generateBlankRows(numRows);
        setRows((prevRows) => [...prevRows, ...newRows]);
    }

    function ButtonComponent() {
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        addRow(5);
                    }}
                >
                    Add 5 more Check Points
                </Button>

            </>
        );
    }

    function handleSave() {
        var slNO = componentProps.maxID + 1;
        let payload = [];
        gridApi?.forEachNode((node) => {
            if (!node.data.maintenance_question) {
                return;
            }
            payload.push({
                id: uuidv4(),
                client_id: currentUser.client_id,
                user_id: currentUser.id,
                maintenance_question: node.data.maintenance_question,
                q_number: slNO,
            });
            slNO++;
        });
        //console.log(payload);
        machineMaintenanceService.createMachineMaintenanceQ(payload)
            .then((res) => {
                closeForm();
            })
            .catch((err) => {
                console.log(err);
            });
    }



    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            minWidth: 76,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "serial_number",
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },
        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 500,
            editable: true,
        },

    ]





    return (
        <>
            <Grid item md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    gridheight={115}
                    onGridReady={onGridReady}
                    needExport={false}
                    ButtonComponent={ButtonComponent}
                />
            </Grid>





            <Footer onSave={handleSave} />



        </>
    )


}