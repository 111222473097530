import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { DGInput } from "../../../../components/shared/DGInput";
import { AuthContext } from "../../../../context/AuthContextProvider";

export function StaticHeader1({
  data = [],
  form = null,
  hangeRecordDisabled = () => {},
}) {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <Grid item md={2} sm={2} lg={2}>
        <img
          src={currentUser?.logo}
          alt="logo"
          style={{ height: 60, width: "100%" }}
        />
      </Grid>
      {data?.map((exp, i) => (
        <Grid item md>
          {exp?.item_name === "Invoice Date" ? (
            <DGInput
              id={i}
              label={exp?.item_name}
              value={form?.values[i] ?? ""}
              onChange={(e) => {
                form?.handleChange(e);
                hangeRecordDisabled(true);
              }}
              required={exp?.item_mandatory === 1}
              isFormik
              error={form.errors[i]}
              type="date"
            />
          ) : (
            <DGInput
              id={i}
              label={exp?.item_name}
              value={form?.values[i] ?? ""}
              onChange={(e) => {
                form?.handleChange(e);
                hangeRecordDisabled(true);
              }}
              required={exp?.item_mandatory === 1}
              isFormik
              error={form.errors[i]}
              type="text"
            />
          )}
        </Grid>
      ))}
    </>
  );
}
