import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  DrawerFooter,
} from "@chakra-ui/react";
import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { object, string } from "yup";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import disptachInspection from "../../../../services/disptachInspection";
import { NumericEditor } from "../../../../components/cellRenderers/NumericEditor";
import { AngleEditor } from "../../../../components/cellRenderers/AngleEditor";
import { StaticHeader1 } from "../Plan/StaticHeader1";
import { StaticHeader2 } from "../Plan/StaticHeader2";
import { UserHeader } from "../Plan/UserHeader";
import { GridDetails } from "../Plan/GridDetails";
import { FooterHeader } from "../Plan/FooterHeader";

export function DispatchPlanForm({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {
  const [gridApi, setGridApi] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [dispatch_plan_id, setdispatchPlanId] = useState("");
  const [pd_result_id, setResultId] = useState("");
  const [recordDisabled, setRecordDisabled] = useState(true);
  const [static_1, SetStatic_1] = useState({ init: {}, validate: object() });
  const [static_2, SetStatic_2] = useState({ init: {}, validate: object() });
  const [user_head, SetUserHead] = useState({ init: {}, validate: object() });
  const [planOptions, setPlantoptions] = useState([]);
  const [footer_head, SetFooterHead] = useState({
    init: {},
    validate: object(),
  });
  const [error, setError] = useState({
    open: false,
    type: "info",
    message: "",
    description: "",
  });
  let form = useFormik({
    initialValues: {
      static_header_1: [],
      static_header_2: [],
      user_header: [],
      footer_header: [],
      gridData: [],
      gridColumns: [],
    },
  });

  useEffect(() => {
    if (currentUser?.client_id) {
      disptachInspection
        .getClientPDITable({ client_id: currentUser?.client_id })
        .then((res) => {
          setPlantoptions(res?.data ?? []);
        });
    }

    if (componentProps?.pdi_result_id) {
      disptachInspection
        .getPDIResultView(componentProps?.pdi_result_id)
        .then((res) => {
          let payload = [];
          let samples_size = res?.data?.general?.max_sample_size;
          for (let i = 0; i < samples_size; i++) {
            payload.push({
              field: `${i}`,
              headerName: `${i + 1}`,
              minWidth: 100,
              editable: !ACTION_TYPE.view,
              resizable: true,
              cellEditorSelector: (params) => {
                if (
                  params?.data?.measurement_id !== 303 &&
                  params?.data?.measurement_type !== "303"
                ) {
                  return { component: NumericEditor };
                } else {
                  return {
                    component: "agRichSelectCellEditor",
                    params: {
                      values: ["OK", "NOT OK"],
                    },
                    popup: true,
                  };
                }
              },
              cellStyle: (params) => {
                if (params?.data[`${i}_error`]) {
                  return { backgroundColor: "#ffebee" };
                } else {
                  return null;
                }
              },
              colSpan: (params) => {
                if (
                  Number(params?.colDef?.field) + 1 ===
                  Number(params?.data?.pdi_sample_size)
                ) {
                  return (
                    samples_size - Number(params?.data?.pdi_sample_size) + 1
                  );
                } else {
                  return 1;
                }
              },
            });
          }
          form.setFieldValue(
            "gridData",
            res?.data?.grid_details.map((exp) => {
              let obj = { ...exp };
              exp?.values?.map((e, i) => {
                if (exp?.measurement_type === "303") {
                  return e === 1
                    ? (obj[`${i}`] = "OK")
                    : (obj[`${i}`] = "NOT OK");
                }
                return (obj[`${i}`] = e);
              });
              return obj;
            }) ?? []
          );
          form.setFieldValue("gridColumns", [...cols, ...payload]);
          form.setFieldValue(
            "static_header_1",
            res?.data?.static_header_1 ?? []
          );
          form.setFieldValue(
            "static_header_2",
            res?.data?.static_header_2.map((exp, index) => ({
              ...exp,
              id: index,
            })) ?? []
          );
          form.setFieldValue(
            "user_header",
            res?.data?.user_header.map((exp, index) => ({
              ...exp,
              id: index,
            })) ?? []
          );
          form.setFieldValue(
            "footer_header",
            res?.data?.footer_header.map((exp, index) => ({
              ...exp,
              id: index,
            })) ?? []
          );
          IntializeForm(res?.data?.static_header_1, 0);
          IntializeForm(res?.data?.static_header_2, 1);
          IntializeForm(res?.data?.user_header, 2);
          IntializeForm(res?.data?.footer_header, 3);

          setdispatchPlanId(res?.data?.general?.pdi_dispatch_plan_id);
        });
    }
  }, []);

  let static_header_1 = useFormik({
    initialValues: static_1.init,
    validationSchema: static_1.validate,
    enableReinitialize: true,
    validateOnMount: true,
  });

  let static_header_2 = useFormik({
    initialValues: { ...static_2.init, plantoptions: [] },
    validationSchema: static_2.validate,
    enableReinitialize: true,
    validateOnMount: true,
  });
  let user_header = useFormik({
    initialValues: user_head.init,
    validationSchema: user_head.validate,
    enableReinitialize: true,
    validateOnMount: true,
  });
  let footer_header = useFormik({
    initialValues: footer_head.init,
    validationSchema: footer_head.validate,
    enableReinitialize: true,
    validateOnMount: true,
  });
  let cols = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      minWidth: 105,
    },
    {
      headerName: "Characterstics",
      field: "name",
      resizable: true,
      minWidth: 200,
    },
    {
      headerName: "Specification",
      field: "specification",
      valueGetter: (params) =>
        `${params?.data?.specification} ${params?.data?.upper_specification} ${params?.data?.lower_specification}`,
      resizable: true,
      minWidth: 200,
    },
    {
      headerName: "Gauge",
      field: "plant_name",
      resizable: true,
      valueGetter: (params) =>
        `${params?.data?.gauge_number}-${params?.data?.gauge_name}`,
      minWidth: 150,
    },
    {
      headerName: "Result",
      field: "pdi_char_result",
      resizable: true,
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.pdi_char_result} />
      ),
      minWidth: 150,
    },
  ];

  function IntializeForm(data, head) {
    let intialiValues = {};
    let validationSchema = {};
    data?.map((exp, i) => {
      intialiValues[i] = exp?.default_value;
      if (exp?.item_mandatory === 1) {
        validationSchema[i] = string().required();
      }
    });
    if (head === 0) {
      SetStatic_1({ init: intialiValues, validate: object(validationSchema) });
    } else if (head === 1) {
      SetStatic_2({
        init: intialiValues,
        validate: object(validationSchema),
      });
    } else if (head === 2) {
      SetUserHead({
        init: intialiValues,
        validate: object(validationSchema),
      });
    } else if (head === 3) {
      SetFooterHead({
        init: intialiValues,
        validate: object(validationSchema),
      });
    }
  }

  function loadFormData(id) {
    if (ACTION_TYPE.view) {
      return;
    }
    setdispatchPlanId(id);
    setResultId(componentProps?.pdi_result_id);
    form.resetForm();
    disptachInspection.getPDIPlanDetails(id).then((res) => {
      setResultId(res?.data?.general?.pdi_result_id);
      let payload = [];
      let samples_size = res?.data?.general?.max_sample_size;
      for (let i = 0; i < samples_size; i++) {
        payload.push({
          field: `${i}`,
          headerName: `${i + 1}`,
          minWidth: 100,
          editable: true,
          resizable: true,
          cellEditorSelector: (params) => {
            if (
              params?.data?.measurement_id !== 303 &&
              params?.data?.measurement_type !== "303"
            ) {
              if (params?.data?.character_type_id !== 105) {
                return {
                  component: NumericEditor,
                };
              } else {
                return {
                  component: AngleEditor,
                };
              }
            } else {
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: ["OK", "NOT OK"],
                },
                popup: true,
              };
            }
          },
          cellStyle: (params) => {
            if (params?.data[`${i}_error`]) {
              return { backgroundColor: "#ffebee" };
            } else {
              return null;
            }
          },
          colSpan: (params) => {
            if (
              Number(params?.colDef?.field) + 1 ===
              Number(params?.data?.pdi_sample_size)
            ) {
              return samples_size - Number(params?.data?.pdi_sample_size) + 1;
            } else {
              return 1;
            }
          },
        });
      }
      form.setFieldValue(
        "gridData",
        res?.data?.grid_details?.map((exp, index) => ({ ...exp, id: index })) ??
        []
      );
      form.setFieldValue("gridColumns", [...cols, ...payload]);
      form.setFieldValue("static_header_1", res?.data?.static_header_1 ?? []);
      IntializeForm(res?.data?.static_header_1, 0);
      IntializeForm(res?.data?.static_header_2, 1);
      IntializeForm(res?.data?.user_header, 2);
      IntializeForm(res?.data?.footer_header, 3);
      form.setFieldValue(
        "static_header_2",
        res?.data?.static_header_2.map((exp, index) => ({
          ...exp,
          id: index,
        })) ?? []
      );
      form.setFieldValue(
        "user_header",
        res?.data?.user_header.map((exp, index) => ({ ...exp, id: index })) ??
        []
      );
      form.setFieldValue(
        "footer_header",
        res?.data?.footer_header.map((exp, index) => ({ ...exp, id: index })) ??
        []
      );
    });
  }

  function onGridReady(params) {
    setGridApi(params?.api);
  }

  function updateForm(data) {
    form.setFieldValue(
      "gridData",
      data?.grid_details.map((exp) => {
        let obj = { ...exp };
        exp?.values?.map((e, i) => {
          if (exp?.measurement_type === "303") {
            return e === 1 ? (obj[`${i}`] = "OK") : (obj[`${i}`] = "NOT OK");
          }
          return (obj[`${i}`] = e);
        });
        return obj;
      }) ?? []
    );
  }

  function getStaticHeader1Payload() {
    let static_header_1_payload = [];
    let static_header_object = form.values.static_header_1;
    // console.log("Hello")
    // console.log(static_header_object)
    for (let input in static_header_1?.values) {
      static_header_1_payload.push({
        pdi_dispatch_plan_id: dispatch_plan_id,
        created_by: currentUser?.id,
        pdi_standard_item_id: static_header_object[Number(input)]?.pdi_standard_item_id,
        standard_value: static_header_1?.values[Number(input)],
      });
    }

    //console.log("static_header_1_payload")
    //console.log(static_header_1_payload)

    return static_header_1_payload;
  }

  function getStaticHeader2Payload() {
    let static_header_2_payload = [];
    let static_header_object = form.values.static_header_2;
    for (let input in static_header_2?.values) {
      static_header_2_payload.push({
        pdi_dispatch_plan_id: dispatch_plan_id,
        created_by: currentUser?.id,
        pdi_standard_item_id: static_header_object[Number(input)]?.pdi_standard_item_id,
        standard_value: static_header_2?.values[Number(input)],
      });
    }
    return static_header_2_payload;
  }

  function getUserHeadePayload() {
    let user_header_payload = [];
    let user_header_object = form.values.user_header;
    for (let input in user_header?.values) {
      user_header_payload.push({
        pdi_dispatch_plan_id: dispatch_plan_id,
        created_by: currentUser?.id,
        pdi_standard_item_id: user_header_object[Number(input)]?.pdi_standard_item_id,
        standard_value: user_header?.values[Number(input)],
      });
    }

    return user_header_payload;
  }

  function getFooterHeadePayload() {
    let footer_header_payload = [];
    let footer_header_object = form.values.footer_header;
    for (let input in footer_header?.values) {
      footer_header_payload.push({
        pdi_dispatch_plan_id: dispatch_plan_id,
        created_by: currentUser?.id,
        pdi_standard_item_id: footer_header_object[Number(input)]?.pdi_standard_item_id,
        standard_value: footer_header?.values[Number(input)],
      });
    }

    return footer_header_payload;
  }

  function getGridData() {
    let grid_payload = [];
    let isValidationFailed = false;
    let gridData = [];
    gridApi?.forEachNode((node) => {
      let data = node.data;
      let obj = {
        pdi_dispatch_plan_id: dispatch_plan_id,
        character_id: data?.character_id,
        values: [],
      };
      for (let i = 0; i < data?.pdi_sample_size ?? 0; i++) {
        if (data[`${i}`]) {
          if (
            data?.measurement_id === 303 ||
            data?.measurement_type === "303"
          ) {
            let val;
            if (data[`${i}`] === "OK") {
              val = 1;
            } else {
              val = 0;
            }
            obj.values?.push(val);
          } else {
            if (data?.character_type_id === 105) {
              obj.values?.push(data[`${i}`]);
            } else {
              obj.values?.push(Number(data[`${i}`]));
            }
          }
          data[`${i}_error`] = false;
        } else {
          isValidationFailed = true;
          data[`${i}_error`] = true;
        }
      }
      grid_payload?.push(obj);
      gridData.push(data);
    });
    form.setFieldValue("gridData", gridData ?? []);
    return { grid_payload, isValidationFailed };
  }

  function onSave(isRecord = false) {
    let payload_1 = getStaticHeader1Payload();
    let payload_2 = getStaticHeader2Payload();
    let payload_3 = getUserHeadePayload();
    let payload_4 = getGridData();
    let payload_5 = getFooterHeadePayload();
    if (payload_4?.isValidationFailed) {
      return;
    }
    let obj = {
      static_header_1: payload_1,
      static_header_2: payload_2,
      user_header: payload_3,
      grid_details: payload_4?.grid_payload ?? [],
      footer_header: payload_5,
      general: {
        pdi_result_id: pd_result_id,
        pdi_dispatch_plan_id: dispatch_plan_id,
      },
    };

    disptachInspection
      .createNewPDIReportEvaluate(obj)
      .then((res) => {
        updateForm(res?.data ?? []);
        setRecordDisabled(
          res?.data?.general?.pdi_result_status === 1 ||
          res?.data?.general?.pdi_result_status === 3
        );
        if (
          res?.data?.general?.pdi_result_status === 1 ||
          res?.data?.general?.pdi_result_status === 3
        ) {
          // updateAlert({
          //   message: "Dispatch Report has Errors",
          //   open: true,
          //   type: "info",
          // });
          setError({
            open: true,
            message: "Dispatch Report has Errors",
            type: "error",
            description: "Fix the Error and Revaluate",
          });
        } else {
          // updateAlert({
          //   message: "Dispatch Report Evaluated Sucessfully",
          //   open: true,
          //   type: "info",
          // });
          setError({
            open: true,
            message: "Dispatch Report Evaluated Sucessfully",
            type: "success",
            description: "Dispatch Report Ready for Record",
          });
        }
        if (isRecord) {
          if (
            !static_header_1?.isValid ||
            !static_header_2.isValid ||
            !user_header?.isValid ||
            !footer_header?.isValid
          ) {
            setError({
              open: true,
              message: "Dispatch Report has Errors",
              type: "error",
              description: "Fix the Error and Revaluate",
            });
            return setRecordDisabled(true);
          }
          onRecord(res?.data?.general?.pdi_result_id);
        }
      })
      .catch((exp) => {
        updateAlert({
          message: "Failed To Evaluate Dispatch Report",
          open: true,
          type: "error",
        });
      });
  }

  function onRecord(id) {
    disptachInspection
      .createNewPDIReportRecord({
        pdi_result_id: id,
      })
      .then((res) => {
        closeForm();
        updateAlert({
          message: "Dispatch Report Recorded Sucessfully",
          open: true,
          type: "success",
        });
      })
      .catch(() => {
        updateAlert({
          message: "Failed to Record Dispatch Report",
          open: true,
          type: "error",
        });
      });
  }

  function hangeRecordDisabled(state) {
    if (recordDisabled === state) {
      return;
    } else {
      setRecordDisabled(state);
    }
  }

  useEffect(() => {
    loadFormData(componentProps?.pdi_result_id);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={1} md={12} sm={12} lg={12}>
        <StaticHeader1
          data={form.values.static_header_1}
          key={1}
          form={static_header_1}
          hangeRecordDisabled={hangeRecordDisabled}
        />
      </Grid>
      <Grid item container spacing={1} md={12} sm={12} lg={12}>
        <StaticHeader2
          data={form.values.static_header_2}
          loadFormData={loadFormData}
          form={static_header_2}
          hangeRecordDisabled={hangeRecordDisabled}
          planOptions={planOptions}
          key={2}
        />
      </Grid>
      <Grid item container spacing={1} md={12} sm={12} lg={12}>
        <UserHeader
          data={form.values.user_header}
          key={3}
          form={user_header}
          hangeRecordDisabled={hangeRecordDisabled}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <GridDetails
          rowData={form.values.gridData}
          columns={form.values.gridColumns}
          onGridReady={onGridReady}
          hangeRecordDisabled={hangeRecordDisabled}
          key={4}
        />
      </Grid>
      <Grid item container spacing={2} md={12} sm={12} lg={12} className="mb-4">
        <FooterHeader
          key={4}
          data={form.values.footer_header}
          hangeRecordDisabled={hangeRecordDisabled}
          form={footer_header}
        />
      </Grid>
      <DrawerFooter>
        {!ACTION_TYPE.view && (
          <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
            <Divider />
            <Toolbar>
              {error.open && (
                <Alert status={error.type}>
                  <AlertIcon />
                  <AlertTitle>{error.message}</AlertTitle>,
                  <AlertDescription>{error.description}</AlertDescription>
                </Alert>
              )}
              <div style={{ flexGrow: 1 }}></div>
              <Button
                style={{ margin: 4 }}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => closeForm()}
              >
                CANCEL
              </Button>
              <Button
                style={{ margin: 4 }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => onSave(false)}
              >
                EVALUATE
              </Button>
              <Button
                style={{ margin: 4 }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => onSave(true)}
              >
                RECORD
              </Button>
            </Toolbar>
          </AppBar>
        )}
      </DrawerFooter>
    </Grid>
  );
}
