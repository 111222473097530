/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button, IconButton } from "@material-ui/core";
import CommonGrid from "../../../../components/CommonGrid";
import disptachInspection from "../../../../services/disptachInspection";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ProductDispatchForm } from "../../../characterstics/productDetails/productiondispatch/ProductDispatchForm";
import { DGFullScreenDrawer } from "../../../../components/shared/DGFullscreenDrawer";
import { DispatchPlanForm } from "./DispatchPlanForm";

export function DispatchPlanListing() {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState({});

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setAddOpen(true);
          }}
          style={{ marginLeft: 3 }}
        >
          DISPATCH REPORT
        </Button>
      </>
    );
  }

  function loadForm(data) {
    setSelectedRow(data);
    ACTION_TYPE.view = true;
    setFormOpen(true);
  }

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 105,
      width: 100,
    },
    {
      headerName: "Part Details",
      field: "plan_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <>
            <Button
              size="small"
              color="primary"
              onClick={() => loadForm(params?.data)}
            >
              {`${params?.data?.part_number}-${params?.data?.part_name}`}
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Plan Number",
      field: "dispatch_plan_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Standard Checklist Name",
      field: "std_plan",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
  ];

  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    disptachInspection
      .getClientPDITable({ client_id: currentUser?.client_id })
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
        gridheight={215}
      />
      <DGDrawer
        Component={ProductDispatchForm}
        isOpen={formOpen}
        setOpen={(state) => {
          setFormOpen(state);
          reloadgriddata();
          setSelectedRow(null);
        }}
        title="Add Dispatch Inspection Plan"
        componentProps={{
          plant: selectedRow?.plant?.name,
          part_number: selectedRow?.part_number,
          part_id: selectedRow?.id,
          plant_id: selectedRow?.plant_id,
          id: selectedRow?.id,
          plan_name: selectedRow?.dispatch_plan_number,
          std_plan: selectedRow?.std_plan,
        }}
        drawerWidth={1150}
        needSave={!ACTION_TYPE.view}
      />
      <DGFullScreenDrawer
        isOpen={addOpen}
        setOpen={() => {
          setAddOpen(false);
          reloadGridData();
        }}
        Component={DispatchPlanForm}
        size="full"
        needSave
      />
    </>
  );
}
