import http from "./httpService";

const uploadFile = async (files) => {
    const formData = new FormData();
    files && files.length && await Promise.all(files.map((file) => {
        formData.append('upload', file)
    }));
    const response = await http.post('/upload', formData, {
        headers: {
           'Content-Type': 'multipart/form-data',
           "Access-Control-Allow-Origin": "*",
        }
    });
    return response;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    uploadFile
};
