import { Button } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { DGDrawer } from "../../../../components/DGDrawer";
import productionInspection from "../../../../services/productionInspection";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { useEffect } from "react";
import { PITrendDetail } from "./PITrendDetail";
import { format } from 'date-fns'

export function PITrendList() {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [plantFlag, setPlantFlag] = useState(0)
    const [formOpen, setFormOpen] = useState(false);
    const [subtitle, setSubtitle] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [mainTitle, setMainTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);

    useEffect(() => {
        if (globalPlant.globalPlant.id === currentUser?.client_id) {
            setPlantFlag(1)
        }
        reloadgridData()
    }, [globalPlant]);


    // console.log(selectedRow)

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadgridData,
        }));
        if (globalPlant && globalPlant?.globalPlant?.id) {
            reloadgridData();
        }
    }, []);

    function reloadgridData() {
        productionInspection
            .getPITrendList({
                client_id: currentUser?.client_id,
                query_id: globalPlant?.globalPlant?.id,
            })
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }

    const columns = [
        {
            headerName: "Sl No.",
            valueGetter: "node.rowIndex + 1",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            maxWidth: 105,
            pinned: "left",
        },
        {
            field: "part_number",
            headerName: "Part Number",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            cellRenderer: (params) => {
                return (
                    `${params?.data?.part_number}-${params?.data?.part_name}`
                )
            }
        },
        {
            headerName: "Specification",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            LoadDetailForm(params?.data);
                            titleDetails(params?.data);
                        }}>

                        {`${params?.data?.character_name} ${"  "}
                        ${params?.data?.specification ? params.data.specification : ""}
                        ${params?.data?.upper_specification ? params.data.upper_specification : ""}
                        ${params?.data?.lower_specification ? params.data.lower_specification : ""} `}
                    </Button>
                );
            },

        },

        {
            headerName: "Evaluation Count",
            resizable: true,
            field: "count_character",
            minWidth: 40,
        },

        {
            headerName: "Last Evaluated",
            resizable: true,
            // field: "latest_date",
            minWidth: 40,
            valueGetter: (params) =>
                format(new Date(params?.data?.latest_date), 'dd/MM/yyyy')

        }
    ];

    function LoadDetailForm(data) {
        const updatedData = { ...data };
        updatedData.only_graph = 0;
        setSelectedRow(updatedData);
        setFormOpen(true);
    }

    function titleDetails(data) {
        setMainTitle(
            `${data?.character_name} ${"  "}
                        ${data?.specification ? data.specification : ""}
                        ${data?.upper_specification ? data.upper_specification : ""}
                        ${data?.lower_specification ? data.lower_specification : ""} `)
        setSubTitle(`${data?.part_number}-${data?.part_name}`)

    }



    return (
        <>
            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={225}
            // onRowSelect={onRowSelection}
            />

            <DGDrawer
                Component={PITrendDetail}
                title={subTitle}
                //subtitle={}
                isOpen={formOpen}
                setOpen={(state) => {
                    setSubtitle("");
                    setFormOpen(state);
                }}

                drawerWidth={1150}
                componentProps={selectedRow}
            />
        </>
    );
}

var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};
