import React, { useCallback, useRef, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "./commonGrid.css";
import GridLoading from "./GridLoading";
import useWindowSize from "../hooks/windowSize.hook";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import "./styles/commonStyles.css";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function CommonGrid({
  id = "myGrid",
  rows,
  columns = [],
  gridheight = 225,
  topRowsPinnedCount = 0,
  columnToolpanel = false,
  needExport = true,
  fixedHeight = false,
  onGridReady = () => { },
  coldefs = () => {
    return {
      floatingFilter: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      flex: 1,
      customFilterParams: true,
      autoshowOpenedGroup: false,
      //rowGroupPanelShow: false,

    };
  },
  textmsg = "",
  rowHeight = 40,
  horizontalTab = false,
  ButtonComponent = () => null,
  onCellClick = (event) => { },
  onRowSelect = () => { },
  tableName = "",
  processCellFromClipboard = (params) => params?.value,
  headerHeight = undefined,
  editType = null,
  onRowValueChanged = () => { },
  onFilterChanged = () => { },
  suppressRowTransform = false,
  rowSelection = "single",
  rowGroupPanelShow = "never",
  HeaderComponent = null,
  singleClickEdit = false,
  suppressCellSelection = false,
  checkboxSelection = false,
  showDisabledCheckboxes = false,
  suppressRowClickSelection = false,
  suppressCellFocus = false,
  onCellValueChanged = null,
  rowDragManaged = "",
  enableRowDrag = "",
  onRowDragEnd = "",
  enableAdvancedFilter = "",
  rowDataChanged = "",
  groupDefaultExpanded = 0,
  grandTotalRow = "top",
  suppressRowSticky = false,
  groupDisplayType = 'singleColumn',
  suppressAggFuncInHeader = true,
  groupRowRenderer = null,
  onRowDataUpdated = () => { },


  getRowStyle = () => null,
}) {
  const classes = useStyles();
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  const size = useWindowSize();
  useEffect(() => {
    if (Array.isArray(rows)) {
      setRowData(rows);
    }
    setColumnDefs(columns);
  }, [rows, columns]);
  useEffect(() => {
    if (gridRef) {
      gridRef?.current?.api?.sizeColumnsToFit();
      if (rowData?.length === 0) {
        gridRef?.current?.api?.hideOverlay();
        gridRef?.current?.api?.showNoRowsOverlay();
      }
    }
  });
  useEffect(() => {
    if (topRowsPinnedCount > 0 && rows.length > 0) {
      pinRows(rows.slice(0, topRowsPinnedCount));
    }
  }, [topRowsPinnedCount, rows]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressSyncLayoutWithGrid: true,
            suppressColumnMove: true,
            // suppressRowGroups: true,
            //   suppressValues: true,
            //   suppressPivots: true,
            //   suppressPivotMode: true,
            //   suppressColumnFilter: true,
            //   suppressColumnSelectAll: true,
            //   suppressColumnExpandAll: true,
          },
          defaultToolPanel: "columns",
        },
      ],
      defaultToolPanel: "columns",
    };
  }, []);

  function Export() {
    gridRef.current.api.exportDataAsExcel();
  }

  const pinRows = useCallback((rows) => {
    gridRef.current.api.setPinnedTopRowData(rows);
  }, []);


  const onSelectionChanged = useCallback(() => {
    onRowSelect(gridRef.current.api.getSelectedRows());
  }, []);

  return (
    <Paper>
      {
        //needExport || ButtonComponent() ? (
        <Grid
          container
          style={{
            border: "solid 1px #babfc7",
            borderBottom: "0px",
            textAlign: "end",
            padding: 8,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {HeaderComponent ? (
            <Grid
              item
              md={6}
              sm={6}
              lg={6}
              justifyContent="space-between"
              alignItems="flex-start"
              alignContent="flex-start"
            >
              <div className="w-100 mr-auto" style={{ display: "flex" }}>
                {" "}
                <HeaderComponent />
              </div>
            </Grid>
          ) : (
            <Grid
              item
              md={6}
              sm={6}
              lg={6}
              alignContent="center"
              alignItems="center"
            >
              <Typography
                color="primary"
                variant="h6"
                style={{ fontSize: 18, fontWeight: "bolder", paddingLeft: 10 }}
                align="left"
              >
                {tableName}
              </Typography>
            </Grid>
          )}

          {needExport ? (
            <Grid item md={6} sm={6} className={classes?.addButtonLayout}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloudDownloadOutlinedIcon />}
                style={{ margin: 2, marginRight: 4 }}
                onClick={Export}
              >
                DOWNLOAD
              </Button>
              <ButtonComponent />
            </Grid>
          ) : (
            <Grid item md={6} sm={6} className={classes?.addButtonLayout}>
              <ButtonComponent />
            </Grid>
          )}
          {textmsg}
        </Grid>
        // ) : null
      }

      <div
        className="ag-theme-material"
        style={{

          height: fixedHeight ? gridheight : size.height - (gridheight + 70),
          width: "100%",
          border: "solid 1px #babfc7",
        }}
        id={id}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          ref={gridRef}
          rowHeight={rowHeight}
          defaultColDef={coldefs}
          sideBar={columnToolpanel ? sideBar : null}
          onGridReady={onGridReady}
          loadingOverlayComponent={GridLoading}
          rowSelection={rowSelection}
          checkboxSelection={checkboxSelection}
          suppressRowClickSelection={suppressRowClickSelection}
          suppressRowTransform={suppressRowTransform}
          suppressCellFocus={suppressCellFocus}
          onCellValueChanged={onCellValueChanged}
          tabToNextCell={(params) => {
            if (params?.nextCellPosition?.rowIndex <= rowData.length) {
              if (horizontalTab) {
                params.nextCellPosition.columnIndex =
                  params.previousCellPosition.columnIndex + 1;
                return params.nextCellPosition;
              }
              params.nextCellPosition.rowIndex =
                params.nextCellPosition.rowIndex + 1;
              params.nextCellPosition.column =
                params.previousCellPosition.column;
              return params.nextCellPosition;
            }
            return null;
          }}
          onCellClicked={onCellClick}
          onSelectionChanged={onSelectionChanged}
          processCellFromClipboard={processCellFromClipboard}
          headerHeight={headerHeight}
          editType={editType}
          onRowValueChanged={onRowValueChanged}
          onFilterChanged={onFilterChanged}
          singleClickEdit={singleClickEdit}
          // groupDisplayType="groupRows"
          groupDefaultExpanded={groupDefaultExpanded}
          getRowStyle={getRowStyle}
          rowDragManaged={rowDragManaged}
          enableRowDrag={enableRowDrag}
          onRowDragEnd={onRowDragEnd}
          rowDataChanged={rowDataChanged}
          suppressCellSelection={suppressCellSelection}
          enableAdvancedFilter={enableAdvancedFilter}
          showDisabledCheckboxes={showDisabledCheckboxes}
          rowGroupPanelShow={rowGroupPanelShow}
          grandTotalRow={grandTotalRow}
          suppressRowSticky={suppressRowSticky}
          groupDisplayType={groupDisplayType}
          suppressAggFuncInHeader={suppressAggFuncInHeader}
          groupRowRenderer={groupRowRenderer}
          onRowDataUpdated={onRowDataUpdated}
        ></AgGridReact>
      </div>
    </Paper>
  );
}
