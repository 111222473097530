import { Grid, TextareaAutosize } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useEffect } from "react";
import { array, date, object, string } from "yup";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDateTimePicker } from "../../../../components/shared/DGDateTimePicker";
import { DGInput } from "../../../../components/shared/DGInput";
import productionInspection from "../../../../services/productionInspection";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { useState } from "react";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import { Text } from "@chakra-ui/react";

export function ResultForm({
  closeForm = () => { },
  componentProps = null,
  setHeaderButtons = () => { },
  Footer = () => null,
}) {
  const [selectedRow, setSelectedRow] = useState([]);
  const [columns, setColumns] = useState([]);
  const [maxsample, setMaxSample] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const { updateAlert } = useContext(AlertContext);
  const { currentUser } = useContext(AuthContext);
  const [widthG, setWidthG] = useState("")

  let form = useFormik({
    initialValues: {
      plant: null,
      batch: null,
      part: null,
      production_date: null,
      gridData: [],
      result: "",
      record_flag: false,
      tool: null,
      comments: "",
    },
    validationSchema: object({
      plant: string().required(),
      part: string().required(),
      production_date: date().required(),
      gridData: array().min(1).required(),
    }),
  });

  useEffect(() => {
    ACTION_TYPE.view = true;
    loadColumns(maxsample);
  }, [maxsample]);

  function onGridReady(params) {
    setGridApi(params?.api);
  }

  useEffect(() => {
    setHeaderButtons([
      () => <ResultRenderer value={componentProps?.request_result} />,
    ]);

    LoadInspectRequestdata();
  }, []);

  function LoadInspectRequestdata() {
    productionInspection
      .getInspectionRequest(componentProps?.request_id)
      .then((res) => {
        loadColumns(res?.data?.header[0]?.max_sample_size);
        let arrayData = res?.data?.grid?.map((exp) => ({
          ...exp,
          ...exp?.raw_data?.map((e) => e),
        }));
        form.setFieldValue("gridData", arrayData ?? []);
        let header = res?.data?.header[0];
        form.setFieldValue(
          "part",
          `${header?.part_number}-${header?.part_name}`
        );
        const d1 = new Date(header?.production_date)
        const d2 = d1.toLocaleDateString('en-GB')
        const t1 = new Date('1970-01-01T' + header?.production_time)
        const t2 = t1.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })

        form.setFieldValue("production_date", `${d2} ${t2}`
        );
        form.setFieldValue("record_flag", header?.record_flag === "1");
        form.setFieldValue("plant", `${header?.plant_name}`);
        form.setFieldValue("batch", header?.batch_details);
        form.setFieldValue("comments", header?.comments);
        form.setFieldValue("tool", {
          tool_name: header?.tool_name,
          tool_number: header?.tool_number,
          machine_name: header?.machine_name,
          machine_number: header?.machine_number
        });

        header?.toolFlag === 1
          ? setWidthG(6)
          : setWidthG(12);

        header?.max_sample_size
          ? setMaxSample(header?.max_sample_size)
          : setMaxSample(0);
      });
  }

  function RecordRequest() {
    productionInspection
      .createInspectionRecord(componentProps?.request_id)
      .then((res) => {
        updateAlert({
          open: true,
          message: "Request Recorded SucessFully",
          type: "success",
        });
        closeForm();
      })
      .catch(() => {
        updateAlert({
          open: true,
          message: "Failed To Record Request",
          type: "error",
        });
      });
  }


  console.log(form.values, "form values")

  function loadColumns(samples = 0) {
    let entries = [];
    for (let i = 0; i < samples; i++) {
      entries.push({
        field: `${i}`,
        minWidth: 150,
        headerValueGetter: (params) => Number(params?.colDef?.field) + 1,
        valueGetter: (params) => {
          if (params?.data?.measurement_type === 303) {
            if (params?.data[i] === 1) {
              return "OK";
            } else if (params?.data[i] === 0) {
              return "NOT OK";
            } else {
              return "";
            }
          } else {
            return params?.data[i];
          }
        },
      });
    }
    let static_columns = [
      {
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        filter: "agTextColumnFilter",
        minWidth: 75,
        resizable: true,
      },
      {
        field: "process_number",
        headerName: "Process #",
        filter: "agTextColumnFilter",
        minWidth: 75,
        resizable: true,
      },

      {
        field: "specification",
        headerName: "Characterstics",
        filter: "agTextColumnFilter",
        minWidth: 200,
        resizable: true,
        autoHeight: true,
        cellRenderer: (params) => {
          return (
            <>
              <Text height={15} fontSize="xs">
                {params?.data?.characterstics}
              </Text>

              {params?.data?.specification !== null &&
                params?.data?.specification !== undefined && (
                  <Text height={15} fontSize="xs">
                    {`${params?.data?.specification}  ${params?.data?.upper_specification ?? ""
                      }  ${params?.data?.lower_specification ?? ""}`}
                  </Text>
                )}

              <Text height={15} fontSize="xs">
                {params?.data?.measurement_type_name}
              </Text>

              {params?.data?.machine_spindle_name !== null &&
                params?.data?.machine_spindle_name !== undefined && (
                  <Text height={15} fontSize="xs">
                    {params?.data?.machine_spindle_name}
                  </Text>
                )}

              {params?.data?.gauge_number !== null &&
                params?.data?.gauge_number !== undefined && (
                  <Text height={15} fontSize="xs">
                    {params?.data?.gauge_number} {params?.data?.gauge_name}
                  </Text>
                )}

              {params?.data?.reduced_tolerance !== null &&
                params?.data?.reduced_tolerance !== undefined && (
                  <Text height={15} marginBottom={5} fontSize="xs">
                    {`${params?.data?.reduced_tolerance}% of Tolerance`}
                  </Text>
                )}

              <Text marginBottom={5}></Text>
            </>
          );
        },
        // tooltipValueGetter: (params) => `${params?.data?.characterstics}`,
        // valueGetter: (params) => {
        //   return `${params?.data?.specification}  ${params?.data?.upper_specification}  ${params?.data?.lower_specification}`;
        // },
      },
      {
        field: "character_result",
        headerName: "Result",
        cellRenderer: (params) => (
          <ResultRenderer value={params?.data?.character_result} />
        ),
        minWidth: 100,
        resizable: true,
      },
    ];
    setColumns([...static_columns, ...entries]);
  }

  function onRowSelect(data) {
    setSelectedRow(data);
  }

  function EvaluateData() {
    let payload = [];
    selectedRow.forEach((node) => {
      let data = node;
      let values = [];
      for (let i = 0; i < data?.sample_size; i++) {
        values.push(Number(data[i]));
      }
      payload.push({
        changed_data: 1,
        id: data?.id,
        character_id: data?.character_id,
        measured_by: currentUser?.id,
        reduced_tolerance: data?.reduced_tolerance,
        product_char_flag: data?.product_char_flag,
        values: values,
      });
    });
    productionInspection
      .createInspectionEvaluate(payload)
      .then((res) => {
        updateAlert({
          open: true,
          message: "Request Evaluated",
          type: "success",
        });
        LoadInspectRequestdata();
      })
      .catch(() => {
        updateAlert({
          open: true,
          message: "Failed to Evaluate Request",
          type: "error",
        });
      });
  }


  const getRowStyle = (params) => {
    const result_status = params?.data?.character_result;

    switch (result_status) {
      case 0:
        return { background: 'rgba(231,76,60, 0.1)' };
      case 1:
        return { background: 'rgba(39,174,96, 0.1)' };
      case 2:
        return { background: 'rgba(64,3,3, 0.1)' };
      case -1:
        return { background: 'rgba(153,12,24, 0.05)' };
      case 3:
        return { background: 'rgba(188,188,188,0.2)' };
      default:
        return {};
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          id="plant"
          label="Plant"
          value={form.values.plant}
          onChange={form.handleChange}
          isFormik
          required
          disabled={!ACTION_TYPE.view}
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          id="part"
          label="Part"
          value={form.values.part}
          onChange={form.handleChange}
          isFormik
          required
          disabled={!ACTION_TYPE.view}
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          label="Production Time"
          reguired
          value={form.values.production_date}
          onChange={(val) => form.setFieldValue("production_date", val)}
          format="yyyy/MM/dd HH:mm"
          disabled
        />
      </Grid>

      <Grid item md={widthG} sm={widthG} lg={widthG}>
        <DGInput
          id="batch"
          label="Batch Details"
          value={form.values.batch}
          onChange={form.handleChange}
          isFormik
          error={form.touched.batch && Boolean(form.errors.batch)}
        //disabled
        />
      </Grid>
      {widthG === 6 && (
        <Grid item md={widthG} sm={widthG} lg={widthG}>
          <DGInput
            label="Mould Details"
            value={
              `${form.values?.tool?.tool_name} ${form.values.tool?.tool_number}  
              ${form.values?.tool?.machine_number} ${form.values.tool?.machine_name}`
            }
            disabled
          />
        </Grid>

      )}
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          rows={form.values.gridData}
          columns={columns}
          needExport={false}
          rowSelection={"multiple"}
          onRowSelect={onRowSelect}
          onGridReady={onGridReady}
          getRowStyle={getRowStyle}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <TextareaAutosize
          id="comments"
          label="Comments"
          maxRows={2}
          minRows={2}
          className="w-100 border h-100"
          aria-label="maximum height"
          placeholder="Comments"
          value={form.values.comments}
        />
      </Grid>
      <Footer />
    </Grid>
  );
}
