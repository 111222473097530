import { Select } from "@chakra-ui/react";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { DGInput } from "../../../../components/shared/DGInput";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";

export function StaticHeader2({
  data = [],
  loadFormData = () => {},
  form = null,
  hangeRecordDisabled = () => {},
  planOptions = [],
}) {
  return (
    <>
      <Grid item md={2} sm={2} lg={2}>
        <Select
          variant="filled"
          size={"lg"}
          value={form.values.dispatch_plan?.id}
          placeholder="Dispatch Plan"
          onChange={(val) => {
            form.setFieldValue("dispatch_plan", val?.target?.value);
            loadFormData(val?.target?.value);
            hangeRecordDisabled(true);
          }}
          disabled={ACTION_TYPE.view}
        >
          {planOptions?.map((exp) => (
            <option value={exp.id}>{exp?.dispatch_plan_name}</option>
          ))}
        </Select>
      </Grid>
      {data?.map((exp, i) => (
        <Grid item md>
          <DGInput
            id={i}
            label={exp?.item_name}
            value={form?.values[i] ?? ""}
            onChange={(e) => {
              form?.handleChange(e);
              hangeRecordDisabled(true);
            }}
            isFormik
            size="small"
            required={exp?.item_mandatory === 1}
            error={form.errors[i]}
          />
        </Grid>
      ))}
    </>
  );
}
