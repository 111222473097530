import { useEffect, useContext, useState, useCallback } from "react";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { Grid, Button, AppBar, Divider, Toolbar } from "@material-ui/core";
import { DGInput } from "../../../../components/shared/DGInput";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { format } from "date-fns";
import { func } from "prop-types";


export default function MachineMaintenanaceResultView({
    closeForm = () => { },
    Footer = () => { },
    componentProps = null
}) {

    // console.log("componentProps", componentProps)

    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const [recordFlag, setRecordFlag] = useState(false)
    const [resultStatus, setResultStatus] = useState(-1)
    const [statusQ] = useState([
        { value: 201, label: "OK" },
        { value: 202, label: "Opportunity for Improvement" },
        { value: 203, label: "Not OK" }
    ]);


    function onGridReady(params) {
        setGridApi(params?.api);
    }

    useEffect(() => {
        machineMaintenanceService.getMachineMAintenanceCLPRes(componentProps?.id)
            .then((res) => {
                setRows(res.data)

            })
            .catch((err) => {
                console.log(err)
            })
    }, [])







    function recordFlag1() {
        if (rows.length !== 0) {
            const count = rows.filter(obj => obj.q_result === null).length;
            if (count === 0) {
                setRecordFlag(true)
            }
            else {
                setRecordFlag(false)
            }
        }
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 50,
        },
        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 200,
        },
        {
            field: "q_description",
            headerName: "Description",
            minWidth: 400,
            cellRenderer: (params) => {
                if (!params.value) {
                    return '';
                }
                const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                return wrappedText;
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3'
            },
            autoHeight: true,
        },

        {
            field: "q_result",
            headerName: "Result",
            minWidth: 200,
            cellEditor: "agRichSelectCellEditor",
            cellEditorPopup: true,
            editable: true,
            cellEditorParams: {
                values: statusQ.map(option => option.label),
                cellHeight: 20,
            },
            valueFormatter: (params) => {
                const match = statusQ.find(option => option.value === params.value);
                return match ? match.label : params.value;
            },
            valueSetter: (params) => {
                const match = statusQ.find(option => option.label === params.newValue);
                params.data.q_result = match ? match.value : params.newValue;
                return true;
            },
        }
        ,

        {
            field: "comments",
            headerName: "Comments",
            filter: "agTextColumnFilter",
            minWidth: 400,
        },


    ]


    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <DGInput
                        label="Machine "
                        value={componentProps?.machine_number + "/" + componentProps?.machine_name}
                    />


                </Grid>

                <Grid item xs={6}>
                    <DGInput
                        label="Maintenance Plan "
                        value={componentProps?.plan_number + "/" + componentProps?.plan_name}
                    />

                </Grid>

                <Grid item xs={12}>
                    <DGInput
                        label="Comments"
                        value={componentProps?.comments}
                    />


                </Grid>


                <Grid item xs={12}>

                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={50}
                        onGridReady={onGridReady}
                    />
                </Grid>

            </Grid>

            <Grid container spacing={2}>
                <AppBar position="fixed" style={{ width: 1000, top: "auto", bottom: 0 }}>
                    <Divider />


                    <Toolbar>
                        <Grid item xs={6} style={{
                            paddingTop: "10px", paddingBottom: "10px",
                            paddingLeft: "20px", paddingRight: "20px"
                        }}>
                            <DGInput

                                id="documentDate"
                                variant="outlined"
                                label="Evaluation Date"
                                value={format(new Date(componentProps?.evaluation_date), "yyyy-MM-dd")}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <DGInput

                                id="evaluated_by"
                                variant="outlined"
                                label="Evaluated By"
                                value={componentProps?.evaluated_by}
                            />
                        </Grid>

                    </Toolbar>
                </AppBar>
            </Grid>
        </>
    )

}