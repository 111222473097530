import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import disptachInspection from "../../../../services/disptachInspection";
import { AuthContext } from "../../../../context/AuthContextProvider";
import studyInputService from "../../../../services/studyInputService";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { NumericEditor } from "../../../../components/cellRenderers/NumericEditor";
import { array, object, string } from "yup";
import { ACTION_TYPE } from "../../../../components/shared/commonenum"
const { v4: uuidv4 } = require("uuid");
export function ProductDispatchForm({
  Footer = () => null,
  componentProps = null,
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const [selectedrows, setSelectedRows] = useState([]);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      plan_number: "",
      template: null,
      templateOptions: [],
      gridData: [],
      measurementoptions: [],
      instructions: "",
      plant: "",
    },
    validationSchema: object({
      plan_number: string().required(),
      template: object().required(),
      gridData: array().min(1),
    }),
    onSubmit: saveDispatchPlan,
  });

  useEffect(() => {
    if (componentProps?.id) {
      disptachInspection.getPDIPlanDetails2(componentProps?.id).then((res) => {
        form.setFieldValue("gridData", res?.data?.characteristics);
        form.setFieldValue(
          "plan_number",
          res?.data?.head_details[0]?.dispatch_plan_number
        );
        form.setFieldValue("template", {
          plan_name: res?.data?.head_details[0]?.standard_plan_name,
          id: "",
        });
        form.setFieldValue("plant", res?.data?.head_details[0]?.plant_name);
      });
    } else {
      disptachInspection
        .getDispatchCharList(componentProps?.part_id)
        .then((res) => {
          form.setFieldValue("gridData", res?.data ?? []);
        });
      disptachInspection
        .getPDIStdListClient(currentUser?.client_id, componentProps?.plant_id)
        .then((res) => {
          form.setFieldValue("templateOptions", res?.data ?? []);
        });
      studyInputService.getMeasurementType().then((res) => {
        form.setFieldValue("measurementoptions", res?.data?.data ?? []);
      });
    }
    return () => {
      ACTION_TYPE.view = false;
    };
  }, [componentProps.id]);

  function saveDispatchPlan(values) {
    let payload = [];
    let errorPayload = [];
    let gridArr = form.values.gridData;
    selectedrows?.map((node) => {
      let row = node;
      let index = gridArr.findIndex((exp) => exp.id === row?.id);
      let obj = gridArr[index];
      if (index > -1) {
        if (isNaN(row?.pdi_sample_size) && index > -1) {
          obj.sample_size_validation = true;
          errorPayload.push(1);
        } else {
          obj.sample_size_validation = false;
        }
        if (!row?.measure_type?.id) {
          obj.measure_type_validation = true;
          errorPayload.push(1);
        } else {
          obj.measure_type_validation = false;
        }
        if (!row?.gauge_default?.gauge_id) {
          obj.gauge_default_validation = true;
          errorPayload.push(1);
        } else {
          obj.gauge_default_validation = false;
        }
        gridArr[index] = obj;
      }

      payload.push({
        client_id: currentUser?.client_id,
        plant_id: componentProps?.plant_id,
        part_id: componentProps?.part_id,
        pdi_standard_plan_id: form.values.template?.pdi_standard_plan_id,
        dispatch_plan_name: form.values.plan_number,
        created_by: currentUser?.id,
        instructions: form.values.instructions,
        character_id: row?.id,
        pdi_sample_size: Number(row?.pdi_sample_size),
        gauge_id: row?.gauge_default?.gauge_id,
        measurement_type: row?.measure_type?.id,
      });
    });
    form.setFieldValue("gridData", gridArr);
    if (errorPayload?.length > 0 || payload?.length === 0) {
      return;
    } else {
      disptachInspection
        .createNewPDIplan(payload)
        .then((res) => {
          closeForm();
          updateAlert({
            open: true,
            message: "Plan Created Successfully.",
            type: "success",
          });
        })
        .catch((err) => {
          updateAlert({
            open: true,
            message: "Failed To Create Plan",
            type: "error",
          });
        });
    }
  }

  const columns = [
    {
      headerName: "Characterstics",
      field: "name",
      resizable: true,
      filter: "agTextColumnFilter",
      checkboxSelection: !ACTION_TYPE.view,
    },
    {
      headerName: "Specification",
      field: "plant_name",
      resizable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.specification} ${params?.data?.upper_specification} ${params?.data?.lower_specification}`,
    },
    {
      headerName: "Sample Size",
      field: "pdi_sample_size",
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditor: NumericEditor,
      minWidth: 120,
      editable: !ACTION_TYPE.view,
      cellStyle: (params) => {
        if (params?.data?.pdi_sample_size_validation) {
          return { backgroundColor: "#ffebee" };
        } else {
          return null;
        }
      },
    },
    {
      headerName: "Gauge",
      resizable: true,
      field: "gauge_default",
      filter: "agTextColumnFilter",
      editable: !ACTION_TYPE.view,
      cellEditor: "agRichSelectCellEditor",
      valueGetter: (params) =>
        params?.data?.gauge_default?.gauge_number
          ? params?.data?.gauge_default?.gauge_number
          : "",
      valueSetter: (params) => (params.data.gauge_default = params?.newValue),
      valueFormatter: (params) => {
        return params?.value?.gauge_number;
      },
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: params?.data?.gauge_options ?? [],
          cellHeight: 20,
          formatValue: (value) => value?.gauge_number,
          searchDebounceDelay: 500,
        };
      },
      cellStyle: (params) => {
        if (params?.data?.gauge_default_validation) {
          return { backgroundColor: "#ffebee" };
        } else {
          return null;
        }
      },
    },
    {
      headerName: "Measurement Type",
      resizable: true,
      field: "measure_type.study",
      cellEditor: "agRichSelectCellEditor",
      editable: !ACTION_TYPE.view,
      valueGetter: (params) => params?.data?.measure_type?.study ?? "",
      valueSetter: (params) => (params.data.measure_type = params?.newValue),
      cellEditorPopup: true,
      cellEditorParams: {
        values: form.values.measurementoptions,
        cellHeight: 20,
        formatValue: (value) => value?.study,
        searchDebounceDelay: 500,
      },
      cellStyle: (params) => {
        if (params?.data?.measure_type_validation) {
          return { backgroundColor: "#ffebee" };
        } else {
          return null;
        }
      },
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item md={6} lg={6} sm={6}>
        <DGInput label="Plant" value={form.values.plant} disabled />
      </Grid>
      <Grid item md={6} lg={6} sm={6}>
        <DGInput
          label="Part Number"
          value={componentProps.part_number}
          disabled
        />
      </Grid>
      <Grid item md={6} lg={6} sm={6}>
        <DGInput
          label="Plan Number"
          isFormik
          id="plan_number"
          value={form.values.plan_number}
          onChange={form.handleChange}
          required
          error={form.touched.plan_number && form.errors.plan_number}
        />
      </Grid>
      <Grid item md={6} lg={6} sm={6}>
        <DGSelect
          label="Standard Template"
          value={form.values.template}
          onChange={(val) => form.setFieldValue("template", val)}
          options={form.values.templateOptions}
          getOptionLabel={(opt) => opt?.plan_name}
          required
          error={form.touched.template && form.errors.template}
        />
      </Grid>
      <Grid item md={12} lg={12} sm={12}>
        <CommonGrid
          rows={form.values.gridData}
          columns={columns}
          needExport={false}
          singleClickEdit
          rowSelection={ACTION_TYPE.view ? "single" : "multiple"}
          onRowSelect={setSelectedRows}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Instructions"
          onChange={form.handleChange}
          value={form.values.instructions}
          id="instructions"
          multiline
          rows={3}
          isFormik
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
