import http from "./httpService";


const getProductionRecordTable = async (data) => {
    return http.get('/production-record-table', { params: { ...data } })
}


const getPartListforRecord = async (data) => {

    return http.get('/part-production-select1', { params: { ...data } })
}

const getMachineListforRecord = async (data) => {
    return http.get('/part-production-select2', { params: { ...data } })
}

const getToolListforRecord = async (data) => {
    return http.get('/part-production-select3', { params: { ...data } })
}

const getProductionHistory = async (data) => {
    return http.get('/part-production-select4', { params: { ...data } })
}
const createProductionRecord = (data) => {
    return http.post("/production-record", data);
}

const updateProductionRecord = (id, data) => {
    return http.patch(`/production-record/${id}`, data);
}


const getVSTable = async (data) => {
    return http.get('/valueStream-table', { params: { ...data } })
}

const createVSRecord = (data) => {
    return http.post("/production-valueStream", data);
}

const updateVSRecord = (id, data) => {
    return http.patch(`/production-valueStream/${id}`, data);
}


const getVSMachineTable = async (data) => {
    return http.get('/valueStream-machine-table', { params: { ...data } })
}

const createVSMachineRecord = (data) => {
    return http.post("/production-valueStream-machine", data);
}

const updateVSMachineRecord = (id, data) => {
    return http.patch(`/production-valueStream-machine/${id}`, data);
}

const getOperatorProductionTable = async (data) => {
    return http.get('/production-operator-table', { params: { ...data } })
}


const getVSOperatorTable = async (data) => {
    return http.get('/valueStream-operator-table', { params: { ...data } })
}

const createVSOperatorRecord = (data) => {
    return http.post("/production-valueStream-operator", data);
}

const updateVSOperatorRecord = (id, data) => {
    return http.patch(`/production-valueStream-operator/${id}`, data);
}


const getProductionRecordSelect1 = async (data) => {
    return http.get('/production-record-select1', { params: { ...data } })
}

const getShiftDetailsPlant = async (data) => {
    return http.get('/shift-management-plant', { params: { ...data } })
}

export default {

    getProductionRecordTable,
    getPartListforRecord,
    getMachineListforRecord,
    getToolListforRecord,
    createProductionRecord,
    getProductionHistory,
    getVSTable,
    createVSRecord,
    updateVSRecord,
    getVSMachineTable,
    createVSMachineRecord,
    updateVSMachineRecord,
    getVSOperatorTable,
    createVSOperatorRecord,
    updateVSOperatorRecord,
    getOperatorProductionTable,
    updateProductionRecord,
    getProductionRecordSelect1,
    getShiftDetailsPlant

}