import React, { useEffect, useContext } from "react";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGDrawer } from "../../../../components/DGDrawer";
import { useFormik } from "formik";
import { Grid, AppBar, Divider, Button, Toolbar } from "@material-ui/core";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import machineService from "../../../../services/machineService";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import CommonGrid from "../../../../components/CommonGrid";
import { format } from "date-fns";


export function MacineMaintenanceRequestCreate({

    Footer = () => { },
    closeForm = () => { },
    componentProps = null

}) {
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { v4: uuidv4 } = require('uuid');



    const form = useFormik({

        initialValues: {
            machineOptions: [],
            machineSelected: null,
            planOptions: [],
            planSelected: null,
            gridData: [],
            evaluationDate: new Date(),
            comments: null
        },

    });

    useEffect(() => {
        machineService.getMachineTable(currentUser.client_id,
            globalPlant?.globalPlant?.id)
            .then((res) => {
                form.setFieldValue("machineOptions", res?.data ?? []);
            });
    }, []);


    useEffect(() => {
        if (form.values.machineSelected) {
            machineMaintenanceService.getMachineMaintenanceCLP(
                currentUser.client_id,
                globalPlant?.globalPlant?.id,
            ).then((res) => {
                const data = res?.data ?? []
                const filteredData1 = data.filter((item) => item.machine_id === form.values.machineSelected?.machine_id);
                const filteredData2 = data.filter((item) => item.machine_id === null);
                const filteredData = [...filteredData1, ...filteredData2];
                form.setFieldValue("planOptions", filteredData);
            });
        }


    }, [form.values.machineSelected]);


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150,
        },

        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },

        {
            field: "q_description",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 200,
        }
    ]


    useEffect(() => {
        if (form.values.planSelected) {
            machineMaintenanceService.getMachineMaintenanceCLPPlan(
                form.values.planSelected?.id)
                .then((res) => {
                    form.setFieldValue("gridData", res?.data)
                })
        }
    }, [form.values.planSelected]);

    function onEvaluate() {
        var slNO = componentProps.maxID + 1;
        const tempID = uuidv4();

        const payload1 = {
            id: tempID,
            machine_id: form.values.machineSelected?.machine_id,
            clp_id: form.values?.planSelected.id,
            evaluated_by: currentUser?.id,
            evaluation_date: format(form.values?.evaluationDate, "yyyy-MM-dd"),
            comments: form.values?.comments,
            request_result: null,
            request_number: slNO
        }

        const payload2 = []
        form.values.gridData.forEach(item => {
            payload2.push({
                id: uuidv4(),
                clpRequest_id: tempID,
                q_number: item.q_id,
                comments: null,
                q_result: null,
                action_id: null,
                deviation_criticality: null,
                enabled: 1

            });
        }
        )
        machineMaintenanceService.createMachineMaintenanceCLPReq(payload1)
            .then((res) => {
                if (res.status <= 210) {
                    machineMaintenanceService.createMachineMaintenanceCLPRes(payload2)
                    closeForm();
                }
            })




        // console.log("payload1", payload1)
        // console.log("payload2", payload2)

    }

    function onRequest() {
    }


    return (

        <>

            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <DGSelect
                        label="Machine"
                        name="machineSelected"
                        options={form.values.machineOptions}
                        getOptionLabel={(option) => option.machine_number + " - " + option.machine_name}
                        value={form.values.machineSelected}
                        onChange={(val) => {
                            form.setFieldValue("machineSelected", val);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DGSelect
                        label="Plan"
                        name="planSelected"
                        options={form.values.planOptions}
                        getOptionLabel={(option) => option.plan_number + " - " + option.plan_name}
                        value={form.values.planSelected}
                        onChange={(val) => {
                            form.setFieldValue("planSelected", val);
                        }}
                    />
                </Grid>


                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                    <DGInput

                        id="comments"
                        label="Comments"
                        value={form.values.comments}
                        onChange={(comment) => form.setFieldValue("comments", comment)}
                    />
                </Grid>




                <Grid item md={12} sm={12} lg={12} xs={12}
                    justifyContent="flex-end">


                    <CommonGrid

                        columns={columns}
                        rows={form.values.gridData}
                        gridheight={115}
                        needExport={false}


                    />
                </Grid>





                <Grid item xs={12} >
                    <AppBar position="fixed" style={{ width: 800, top: "auto", bottom: 0 }}>
                        <Divider />


                        <Toolbar>
                            <Grid item xs={6} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                <DGDateInput
                                    required
                                    id="documentDate"
                                    variant="outlined"
                                    label="Evaluation Date"
                                    value={form.values.evaluationDate}
                                    onChange={(date) => form.setFieldValue("evalutationDate", date)}
                                />
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    style={{ margin: 4 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => closeForm()}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    style={{ margin: 4 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={!form.values.planSelected}
                                    onClick={() => onEvaluate()}
                                >
                                    Create Request
                                </Button>
                                {/*   <Button
                                    style={{ margin: 4 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={!form.values.planSelected}
                                    onClick={() => onRequest()}
                                >
                                    Evaluate Request
                                </Button> */}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Grid>

            </Grid >
        </>
    )
}