import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import disptachInspection from "../../../../services/disptachInspection";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { CheckListForm } from "./ChecklistForm";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";

export function ChecklistListing({ Footer = () => { }, closeForm = () => { } }) {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState(null);

  function loadForm(data) {
    setSelectedRow(data);
    ACTION_TYPE.view = true;
    setFormOpen(true);
  }

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      width: 50,
    },
    {
      headerName: "Standard Checklist Name",
      field: "plan_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadForm(params?.data)}
          >
            {`${params?.data?.plan_name}`}
          </Button>
        );
      },
    },
    {
      headerName: "Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
  ];

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormOpen(true);
          }}
          style={{ marginLeft: 3 }}
        >
          STANDARD PLAN
        </Button>
      </>
    );
  }

  function reloadGriddata() {
    disptachInspection.getPDIStdTable(currentUser?.client_id).then((res) => {
      setRows(res?.data ?? []);
    });
  }

  useEffect(() => {
    reloadGriddata();
  }, []);
  return (
    <>
      <CommonGrid
        gridheight={115}
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
      />
      <DGDrawer
        Component={CheckListForm}
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGriddata();
          setSelectedRow(null);
        }}
        title="Standard CheckList"
        drawerWidth={1150}
        needSave={!ACTION_TYPE.view}
        componentProps={{ data: selectedRow }}
      />
    </>
  );
}
