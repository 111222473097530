import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    Paper,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { MachineMaintenanceCheckList } from "./machineMantienanceChecklistPlan/machineMaintenanceCheckList"
//import { MachineMaintenanceResult } from "./machineMaintenanceRequest/machineMaintananceResult"
import { MachineMaintenanceStdQ } from "./machineCheckPoint/machineMaintenanceStdQ"
import { MacineMaintenanceRequestList } from "./machineMaintenanceRequest/machineMaintenanceRequestList"
import { MacineMaintenanceResultList } from "./machineMaintenanceResult/machineMaintenanceResultList"


function MachineMaintenanceTab({ }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    const buttons = [
        "Check Points",
        "Machine Check List",
        "Request",
        "Result"
    ];

    useEffect(() => {
        setHeaderPageTitle(`Machine Maintenance`);
    }, []);

    return (
        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            <Box m={2}>
                {selectedTab === 0 ? <MachineMaintenanceStdQ /> : null}
                {selectedTab === 1 ? <MachineMaintenanceCheckList /> : null}
                {selectedTab === 2 ? <MacineMaintenanceRequestList /> : null}
                {selectedTab === 3 ? <MacineMaintenanceResultList /> : null}



            </Box>
        </Paper>
    );
}

export default MachineMaintenanceTab;

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);
