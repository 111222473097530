import http from "./httpService";

const generateGrr1Report = (data) => {
  return http.post("/report/grr1report", data, { responseType: "arraybuffer" });
};
const generateGrr2Report = (data) => {
  return http.post("/report/grr2report", data, { responseType: "arraybuffer" });
};
const kappaReport = (data) => {
  return http.post("/report/kappareport", data, {
    responseType: "arraybuffer",
  });
};

const generateSignalReport = (data) => {
  return http.post("/report/signalreport", data, {
    responseType: "arraybuffer",
  });
};

const generateDispatchReport = (data) => {
  return http.post("/report/dispatchreport", data, {
    responseType: "arraybuffer",
  });
};

export default {
  generateGrr1Report,
  generateGrr2Report,
  kappaReport,
  generateSignalReport,
  generateDispatchReport,
};
