import { Box, Button, Grid, makeStyles, Typography, Divider } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { array, object, string, number } from "yup";

import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import plantService from "../../../services/plantService"
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import HourChips from "../../../components/DGHourChips";

import errorLog from "../../../services/errorLog";
import productionMgmtService from "../../../services/productionMgmtService";
import productService from "../../../services/productService";
import { format, set } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import DialPadComponent from "../../../components/DialPadComponent";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import { transpertise } from 'polished'
import { get } from "react-hook-form";


export function AddProductionRecord({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null

}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const [rows2, setRows2] = useState([])
    const { globalPlant } = useContext(PlantContext)
    const [fieldFlag, setFieldFlag] = useState(0)
    const [actionStatus, setActionStatus] = useState("")
    const [shiftFlag, setShiftFlag] = useState(1)




    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            // flexDirection: 'column',
            justifyContent: 'left',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '10px',
            width: 'fit-content',
            '& > *': {
                fontSize: '15px',
                padding: "15px",
                justifyContent: 'left',
                width: 'fit-content',
            },
        },
    }));

    const chipclasses = useStyles()

    let form = useFormik({
        initialValues: {
            vsOptions: [],
            vsSelected: "",

            operatorOptions: [],
            operatorChipOptions: [],
            operator: null,

            machineOptions: [],
            machine: null,
            historyGrid: [],
            productionDate: new Date(),
            productionStartTime: "",
            productionEndTime: "",
            quantity: "",
            rejections: "",

            plant: null,
            plantOptions: [],

            partOptions: [],
            partChipOptions: [],
            part: null,

            toolOptions: [],
            tool: null,
            shots: "",
            actionType: null,

            chipOptionsList: [],

            defectMasterOptions: [],
            defectChipMasterOptions: [],
            defectChipOptions: [],
            defectMasterSelected: null,
            incidentQuantity: 0,
            defectQuantityAdded: 0,
            defectGridData: [],

            shiftOptions: [],
            shiftStart: null,
            shiftSelected: null,

        },

        validationSchema: object({

            vsSelected: object().shape({ id: string().required('VS is required') }),
            machine: object().shape({ machine_id: string().required('Machine is required') }),
            operator: object().shape({ operator_id: string().required('Operator is required') }),
            productionStartTime: string().required(),
            productionEndTime: string().required(),
            part: object().shape({ id: string().required('Part is required') }),

            quantity: number().required("Quantity is required"),
            rejections: number().required("Rejections is required"),


        }),


        onSubmit: saveForm

    });


    function onGridReady(params) {
        setGridApi(params.api);

    }


    useEffect(() => {
        getVSTableList()
    }, [globalPlant]);

    function getVSTableList() {
        productionMgmtService.getVSTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }
        ).then((response) => {
            form.setFieldValue("vsOptions", response.data)
        }).catch((error) => {
            console.log(error)
        })

        productionMgmtService.getShiftDetailsPlant({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("shiftOptions", temp);
                if (temp.length === 1 || temp.length === 0) {
                    setShiftFlag(0)
                }
            });



    }

    console.log(form.values?.shiftSelected?.shift_start_time, "shiftOptions", shiftFlag)

    useEffect(() => {
        if (form.values.vsSelected) {
            getMachineList()
            setFieldFlag(1)
        }

    }, [form.values.vsSelected]);

    useEffect(() => {
        if (form.values.machine) {
            getProductionDetails()

        }
    }, [form.values.machine]);

    useEffect(() => {
        if (Object.keys(form.values.chipOptionsList).length > 0) {
            setFieldFlag(2)
            getOperatorList()
        }
    }, [form.values.chipOptionsList]);


    useEffect(() => {
        if (form.values.operator) {
            setFieldFlag(3)
            getOperatorProduction();
        }
    }, [form.values.operator]);

    useEffect(() => {
        if (form.values.productionStartTime) {
            setFieldFlag(4)
        }
    }, [form.values.productionStartTime]);


    useEffect(() => {
        if (form.values.productionEndTime) {
            getPartList()
            setFieldFlag(5)
        }
    }, [form.values.productionEndTime]);


    useEffect(() => {
        if (form.values.part) {
            getToolList((hasToolOptions) => {
                if (hasToolOptions) {
                    setFieldFlag(6);
                } else {
                    setFieldFlag(8);
                }
            });
            getdefectChips()
        }
    }, [form.values.part]);

    useEffect(() => {
        if (form.values.tool) {
            setFieldFlag(7);
        }
    }, [form.values.tool]);

    useEffect(() => {
        if (form.values.rejections > 0) {
            getMasterDefects()
            setFieldFlag(10);
        }
    }, [form.values.rejections]);



    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.defectGridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("defectGridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    const columns = [

        {
            field: "part_name",
            headerName: "Part Number",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.part_number}-{params?.data?.part_name}
                    </>
                )
            },
        },

        {

            field: "machine_name",
            headerName: "Machine",
            flex: 1,
            editable: false,
            resizable: true,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.machine_number}-{params?.data?.machine_name}
                    </>
                )
            }

        },

        {
            field: "production_quantity",
            headerName: "Quantity",
            flex: 1,
        },
        {
            field: "rejections",
            headerName: "Rejections",
            flex: 1,
        },


        {
            field: "production_date",
            headerName: "Production Date",
            flex: 1,

            minWidth: 150,
            valueGetter: (params) =>
                format(new Date(params?.data?.production_date), 'dd/MM/yyyy')
        },


        {
            field: "production_start_time",
            headerName: "Production Time",
            flex: 1,
            minWidth: 150,

        },




    ];




    function getMachineList() {
        productionMgmtService.getVSMachineTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        })
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list =>
                    list.enabled === 1 &&
                    list.production_valueStream_id === form.values.vsSelected.id

                );
                form.setFieldValue("machineOptions", filtList);
            });
    }

    function getPartList() {
        const temp5 = form.values.chipOptionsList?.part_id ?? []
        productService
            .getProductTableByClient(
                globalPlant?.globalPlant?.id,
                currentUser.client_id
            )
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list => list.enabled === 1);
                form.setFieldValue("partOptions", filtList ?? []);

                if (temp5.length === 0) {
                    form.setFieldValue("partChipOptions", filtList);
                    return;
                }
                const filteredList = filtList.filter(item => temp5.includes(item.id));
                form.setFieldValue("partChipOptions", filteredList);
            });
    }

    function getOperatorList() {
        const temp5 = form.values.chipOptionsList?.operator_id ?? []
        productionMgmtService.getVSOperatorTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id

        })
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list =>
                    list.enabled === 1 &&
                    list.production_valueStream_id === form.values.vsSelected.id
                );
                form.setFieldValue("operatorOptions", filtList);

                if (temp5.length === 0) {
                    form.setFieldValue("operatorChipOptions", filtList);
                    return;
                }
                const filteredList = filtList.filter(item => temp5.includes(item.operator_id));
                form.setFieldValue("operatorChipOptions", filteredList);
            })
    }

    function getToolList(callback) {
        productionMgmtService.getToolListforRecord({
            part_id: form.values.part?.id
        })
            .then((res) => {
                const temp = res?.data ?? [];
                form.setFieldValue("toolOptions", temp);

                if (callback) {
                    callback(temp.length > 0);
                }
            });
    }


    function handlemeasuredValue(value) {

        if (form.values.toolOptions.length !== 0) {

            if (form.values.shots === null || form.values.shots === "") {
                form.setFieldValue("shots", value)
                setFieldFlag(8)
            }

            else if (form.values.quantity === null || form.values.quantity === "") {
                form.setFieldValue("quantity", value)
                setFieldFlag(9)
            }
            else {
                form.setFieldValue("rejections", value)
            }

        } else {
            if (form.values.quantity === null || form.values.quantity === "") {
                form.setFieldValue("quantity", value)
                setFieldFlag(9)
            }
            else {
                form.setFieldValue("rejections", value)
            }
        }

    }


    function getProductionDetails() {
        productionMgmtService.getProductionHistory({
            machine_id: form.values.machine?.machine_id
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("chipOptionsList", temp);
            });
    }

    function getOperatorProduction() {
        productionMgmtService.getOperatorProductionTable({
            operator_id: form.values.operator?.operator_id
        })
            .then((res) => {
                const temp = res?.data ?? []
                const filtList = temp.filter(list =>
                    list.enabled === 1 &&
                    new Date(list.production_date) >= new Date(new Date().setDate(new Date().getDate() - 1))
                );

                setRows(temp);
            });


    }

    const labels = [
        "Select Value Stream",
        "Select Machine",
        "Select Operator",
        "Select Production Start Time",
        "Select Production End Time",
        "Select Part",
        "Select Tool",
        "Enter Shots",
        "Enter Production Quantity",
        "Enter Defect Quantity",
        "Enter Defect Details",

    ];

    function saveDefects(tempID) {
        if (form.values.defectGridData.length > 0) {
            let payloadD = []
            form.values.defectGridData.forEach(exp => {
                payloadD.push({
                    id: exp.id,
                    plant_id: globalPlant?.globalPlant?.id,
                    client_id: currentUser?.client_id,
                    part_id: form.values.part?.id,
                    production_record_id: tempID,
                    defect_master_id: exp.defect_master_id,
                    production_quantity: form.values.quantity,
                    defect_quantity: parseInt(exp.defect_quantity),
                    measurement_type: null,
                    basic_eval_id: null,
                    comments: null,
                    ps_project_id: null,
                    machine_id: form.values.machine?.machine_id,
                    machine_spindle_id: null,
                    production_date: format(new Date(form.values.productionDate), 'yyyy/MM/dd'),
                    production_start_time: `${form.values.productionStartTime}:00`,
                    production_end_time: `${form.values.productionEndTime}:00`,
                    defect_incident_id: null,
                    operator_id: form.values.operator.operator_id,
                    enabled: 1
                })
            })


            qualityAnalyticsService.createDefectClassification(payloadD)
                .then((res) => {
                    updateAlert({
                        open: true,
                        message: "Defects recorded Successfully",
                        type: "success",
                    });
                    closeForm();
                })
                .catch((error) => {
                    updateAlert({
                        open: true,
                        message: "Failed to record defects",
                        type: "error",
                    });
                    let payload = {
                        id: uuidv4(),
                        error_description: error.message,
                        error_location: "createDefectClassification",
                        client_id: currentUser.client_id,
                        user_id: currentUser.id,
                        entity_id: null,
                    };
                    errorLog.createErrorLog(payload)

                });
        }
    }



    function saveForm() {
        const tempID = uuidv4()
        let payload = {
            id: tempID,
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser?.client_id,
            part_id: form.values.part?.id,
            form_tool_id: form.values.tool?.tool_id ?? null,
            machine_id: form.values.machine?.machine_id,
            production_quantity: form.values.quantity,
            rejections: form.values.rejections,
            tool_shots: form.values?.shots || 0,
            production_date: format(new Date(form.values.productionDate), 'yyyy/MM/dd'),
            production_start_time: `${form.values.productionStartTime}:00`,
            production_end_time: `${form.values.productionEndTime}:00`,
            operator_id: form.values.operator.operator_id,
            created_by: currentUser?.id,
            enabled: 1,
            record_counter: componentProps.maxID + 1

        }

        productionMgmtService.createProductionRecord(payload)
            .then((res) => {
                if (actionStatus === "close") {
                    saveDefects(tempID)
                    closeForm()
                    updateAlert({
                        open: true,
                        message: "Production record added successfully.",
                        type: "success",
                    });
                } else {
                    saveDefects(tempID)
                    form.resetForm()
                    setFieldFlag(0)
                    getVSTableList()
                    updateAlert({
                        open: true,
                        message: "Production record added successfully.",
                        type: "success",
                    });
                }
            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createProductionRecord",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: "",
                };
                errorLog.createErrorLog(payload)
                updateAlert({
                    open: true,
                    message: "Failed to create Production record.",
                    type: "error",
                });
            });





    }

    const columns2 = [

        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },

        {

            headerName: "Defect",
            field: "defect_name",
            width: 200,
        }
        ,

        {

            headerName: "Defect Quantity",
            field: "defect_quantity",
            width: 200,
        }

    ]

    function getMasterDefects() {
        const temp5 = form.values.defectChipMasterOptions ?? []
        return qualityAnalyticsService.getDefectMasterTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                const data = res?.data ?? []
                form.setFieldValue("defectMasterOptions", data ?? []);
                const data2 = data.filter(list => list.enabled === 1);
                const filteredList = data2.filter(item => temp5.includes(item.id));
                if (temp5.length === 0) {
                    form.setFieldValue("defectChipOptions", data2);
                } else {
                    form.setFieldValue("defectChipOptions", filteredList);
                }

            });

    }

    function onAdd() {
        let data = [];
        data?.push({
            id: uuidv4(),
            defect_name: form.values.defectMasterSelected?.defect_name,
            defect_quantity: form.values.incidentQuantity,
            defect_master_id: form.values.defectMasterSelected?.id,
            defect_quantity: form.values.incidentQuantity

        })
        form.setFieldValue("defectGridData", [...form.values.defectGridData, ...data])

    }

    useEffect(() => {

        const totalDefectQuantity = form.values?.defectGridData.reduce((sum, item) => {
            return sum + parseInt(item.defect_quantity);
        },
            isNaN(parseInt(form.values.incidentQuantity)) ? 0 : parseInt(form.values.incidentQuantity));
        form.setFieldValue("defectQuantityAdded", totalDefectQuantity);

    }, [form.values.incidentQuantity])

    function handlemeasuredValue2(value) {
        form.setFieldValue("incidentQuantity", value)

    }


    function getdefectChips() {
        qualityAnalyticsService.getDefectChipList({
            part_id: form.values.part?.id,
            machine_id: form.values.machine?.machine_id,
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("defectChipMasterOptions", temp);
            });
    }


    return (

        <>
            <Grid container spacing={3} style={{ paddingLeft: '5px' }}>

                <Grid container xs={8} direction="row" spacing={3}>
                    <Grid item xs={4}>
                        <DGSelect
                            id="vs"
                            label="Value Stream"
                            options={form.values.vsOptions}
                            getOptionLabel={(option) => option.valueStream_name}
                            value={form.values.vsSelected}
                            onChange={(value) => {
                                form.setFieldValue("vsSelected", value);
                            }}
                            required
                            error={form.errors.vsSelected && form.touched.vsSelected}

                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DGSelect
                            id="machine"
                            label="Machine"
                            options={form.values.machineOptions}
                            getOptionLabel={(option) => `${option.machine_number ?? ""} ${option.machine_name ?? ""}`}
                            value={form.values.machine ?? ""}
                            error={form.errors.machine && form.touched.machine}
                            onChange={(val) => {
                                form.setFieldValue("machine", val);
                            }}
                        />
                    </Grid>

                    <Grid item md={4} sm={4} lg={4}>
                        <DGSelect
                            id="operator"
                            label="Operator"
                            options={form.values.operatorOptions}
                            getOptionLabel={(option) => option.operator_list_id ? `${option.operator_list_name} ${option.operator_list_id}` : `${option.operator_list_name}`}
                            value={form.values.operator}
                            error={form.errors.operator && form.touched.operator}
                            onChange={(val) => {
                                form.setFieldValue("operator", val);
                            }}
                        />
                    </Grid>

                    <Grid item md={4} sm={4} lg={4}>
                        <DGDateInput
                            label="Production Date"
                            id="productionDate"
                            defaultValue={new Date()}
                            format="dd/MM/yyyy"
                            onChange={(date) => {
                                form.setFieldValue("productionDate", date);
                            }}
                            value={form.values.productionDate}
                            required

                        />
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                        <DGInput
                            label="Production Time"
                            //defaultValue={`${new Date().getHours()}:${new Date().getMinutes()}`}
                            value={form.values.productionStartTime}
                            onChange={(value) =>
                                form.setFieldValue("productionStartTime", value)
                            }
                            type="time"
                            error={form.errors.productionStartTime && form.touched.productionStartTime}
                        />
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                        <DGInput
                            label="Production End Time"
                            //defaultValue={`${new Date().getHours()}:${new Date().getMinutes()}`}
                            value={form.values.productionEndTime}
                            onChange={(value) =>
                                form.setFieldValue("productionEndTime", value)
                            }
                            type="time"
                            error={form.errors.productionEndTime && form.touched.productionEndTime}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DGSelect
                            id="part"
                            label="Part"
                            options={form.values.partOptions}
                            getOptionLabel={(option) => `${option.part_number} ${option.part_name}`}
                            value={form.values.part}
                            onChange={(val) => {
                                form.setFieldValue("part", val);
                            }}

                            required
                            error={form.errors.part && form.touched.part}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DGSelect
                            id="formtool"
                            label="Form Tool"
                            options={form.values.toolOptions}
                            getOptionLabel={(option) => `${option.tool_name} ${option.tool_number}`}
                            value={form.values.tool}
                            error={form.errors.tool && form.touched.tool}
                            disabled={form.values.toolOptions.length === 0}
                            onChange={(val) => {
                                form.setFieldValue("tool", val);

                            }}
                        />
                    </Grid>


                    <Grid item md={4} sm={4} lg={4} >
                        <DGInput
                            id="shots"
                            isFormik
                            label="Shots"
                            onChange={form.handleChange}
                            value={form.values.shots}
                            error={form.errors.shots && form.touched.shots}
                            required
                            disabled={form.values.toolOptions.length === 0}
                        />
                    </Grid>



                    <Grid item md={4} sm={4} lg={4} >
                        <DGInput
                            id="quantity"
                            isFormik
                            label="Quantity"
                            onChange={form.handleChange}
                            value={form.values.quantity}
                            required
                            error={form.errors.quantity && form.touched.quantity}
                        />
                    </Grid>


                    <Grid item md={4} sm={4} lg={4} >
                        <DGInput
                            id="rejections"
                            isFormik
                            label="Rejections"
                            onChange={form.handleChange}
                            value={form.values.rejections}
                            required
                            error={form.errors.rejections && form.touched.rejections}
                        />
                    </Grid>



                    {form.values.rejections > 0 && (
                        <>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                    <DGSelect
                                        id="defectMasterSelected"
                                        label="Defect Master"
                                        options={form.values.defectMasterOptions}
                                        getOptionLabel={(opt) => opt?.defect_name}
                                        value={form.values.defectMasterSelected ?? ""}
                                        //error={form.errors.machine && form.touched.machine}
                                        onChange={(val) => {
                                            form.setFieldValue("defectMasterSelected", val);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DGInput
                                        id="incidentQuantity"
                                        isFormik
                                        label="Incident Quantity"
                                        onChange={form.handleChange}
                                        value={form.values.incidentQuantity}
                                        required
                                    // error={form.errors.quantity && form.touched.quantity}
                                    />

                                </Grid>

                                <Grid item xs={4}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        color="primary"
                                        disabled={form.values.defectMasterSelected === "" ||
                                            form.values.incidentQuantity === "" ||
                                            form.values.defectQuantityAdded > form.values.rejections}
                                        style={{
                                            marginTop: '5px',
                                            width: '30%',
                                        }}

                                        onClick={() => {
                                            onAdd()
                                            form.setFieldValue("incidentQuantity", "")
                                            form.setFieldValue("defectMasterSelected", "")
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Grid>

                                <Grid item md={12} sm={12} lg={12}>
                                    <CommonGrid

                                        rows={form.values.defectGridData?.length ? form.values.defectGridData : []}
                                        columns={columns2}
                                        onGridReady={onGridReady}
                                        needExport={false}
                                        gridheight={410}

                                    />
                                </Grid>

                            </Grid>

                        </>
                    )}

                    <Grid item md={12} sm={12} lg={12}
                        style={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '20px'
                        }}>
                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            style={{
                                marginTop: '5px',
                                width: '30%',
                            }}
                            //onClick={form.handleSubmit}
                            onClick={() => {
                                setActionStatus("close");
                                form.handleSubmit();
                            }}


                        >
                            Record
                        </Button>

                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            style={{
                                marginTop: '5px',
                                width: '30%',
                            }}
                            //onClick={handleCustomSubmit('saveAndRecordAnother')}
                            onClick={() => {
                                setActionStatus("restart");
                                form.handleSubmit();
                            }}
                        >
                            Save & Record Another
                        </Button>

                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            style={{
                                marginTop: '5px',
                                width: '30%',
                            }}
                            onClick={() => {
                                form.resetForm()
                                setFieldFlag(0)
                                getVSTableList()
                                setRows([])
                            }}

                        >
                            Reset
                        </Button>

                    </Grid>

                    <Grid item md={12} sm={12} lg={12}>
                        <CommonGrid

                            rows={rows}
                            columns={columns}
                            //onGridReady={onGridReady}
                            needExport={false}
                            gridheight={410}



                        />
                    </Grid>

                </Grid>

                <Grid item md={4} sm={4} lg={4}>
                    <Box
                        sx={{
                            border: '1px solid grey',
                            width: '98%',
                            padding: '5px',
                            marginTop: '5px',
                            height: '95%',
                            marginRight: '2%',
                            boxSizing: 'border-box',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            height: "600px",
                        }}
                    >

                        <div style={{ marginBottom: '10px' }}>

                            <Typography variant="body2"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    textAlign: "center",
                                }}>

                                {labels[fieldFlag] ?? null}


                            </Typography>

                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Divider
                                style={{
                                    height: '2px',
                                    backgroundColor: 'black',
                                    width: '100%',
                                }}
                            />
                        </div>


                        <div className={chipclasses.root}>
                            {fieldFlag === 0 ? (
                                <>

                                    {shiftFlag === 1 && (
                                        <>
                                            {form.values.shiftOptions.map((item) => (
                                                <Chip
                                                    key={item.shift_name}
                                                    label={`${item.shift_name} Shift - ${item.shift_start_time}`}
                                                    color={form.values.shiftSelected?.shift_name === item.shift_name ? "primary" : "default"}
                                                    variant={form.values.shiftSelected?.shift_name === item.shift_name ? "default" : "outlined"}
                                                    onClick={() => {
                                                        form.setFieldValue("shiftSelected", item);
                                                        form.setFieldValue("shiftStart", item.shift_start_time);
                                                    }}
                                                />

                                            ))}
                                            <div style={{ marginBottom: '20px' }}>
                                                <Divider
                                                    style={{
                                                        height: '2px',
                                                        backgroundColor: 'black',
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}


                                    {form.values.vsOptions.map((item) => (
                                        <Chip
                                            key={item.valueStream_name}
                                            label={item.valueStream_name}
                                            variant="outlined"
                                            onClick={() => form.setFieldValue("vsSelected", item)}
                                        />
                                    ))}

                                </>
                            ) : fieldFlag === 1 ? (
                                form.values.machineOptions.map((item) => (
                                    <Chip
                                        key={item.machine_number}
                                        label={item.machine_number + " - " + item.machine_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("machine", item)}
                                    />
                                ))
                            ) : fieldFlag === 2 ? (
                                form.values.operatorChipOptions.map((item) => (
                                    <Chip
                                        key={item.operator_list_id}
                                        label={item.operator_list_name + " - " + item.operator_list_id}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("operator", item)}
                                    />
                                ))
                            ) : fieldFlag === 3 ? (
                                <HourChips
                                    onTimeSelect={(time) => { form.setFieldValue('productionStartTime', time) }}
                                    endTime={false}
                                    shiftStart={parseInt(form.values?.shiftSelected?.shift_start_time) ?? 6}

                                />
                            ) : fieldFlag === 4 ? (
                                <HourChips
                                    onTimeSelect={(time) => { form.setFieldValue('productionEndTime', time) }}
                                    blockfrom={form.values.productionStartTime}
                                    endTime={true}
                                    shiftStart={parseInt(form.values?.shiftSelected?.shift_start_time) ?? 6}



                                />
                            ) : fieldFlag === 5 ? (
                                form.values.partChipOptions.map((item) => (
                                    <Chip
                                        key={item.part_id}
                                        label={item.part_number + " - " + item.part_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("part", item)}
                                    />
                                ))
                            ) : fieldFlag === 6 ? (
                                form.values.toolOptions.map((item) => (
                                    <Chip
                                        key={item.tool_id}
                                        label={item.tool_number + " - " + item.tool_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("tool", item)}
                                    />
                                ))


                            ) : fieldFlag === 7 || fieldFlag === 8 || fieldFlag === 9 ? (
                                <DialPadComponent
                                    maskValue={0}
                                    maskEvaluation={26}
                                    prodRecordFlag={10}
                                    measuredValue={handlemeasuredValue}

                                />

                            ) : fieldFlag === 10 ? (
                                <>
                                    {form.values.defectChipOptions.map((item) => (
                                        <Chip
                                            key={item.id}
                                            label={item.defect_name}
                                            variant="outlined"
                                            onClick={() => form.setFieldValue("defectMasterSelected", item)}
                                        />
                                    ))}
                                    < DialPadComponent
                                        maskValue={0}
                                        maskEvaluation={26}
                                        prodRecordFlag={10}
                                        measuredValue={handlemeasuredValue2}

                                    />
                                </>
                            ) : (
                                null
                            )
                            }
                        </div>



                    </Box>
                </Grid>



            </Grid>


        </>

    );

}
export default AddProductionRecord;