import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { Button, Grid } from "@material-ui/core";
import { AuthContext } from "../../../../context/AuthContextProvider";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import { DGInput } from '../../../../components/shared/DGInput';
import { PlantContext } from '../../../../context/PlantContextProvider';


export function MachineMaintenanceCLPQView({
    closeForm = () => { },
    componentProps = null


}) {

    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [gridApi, setGridApi] = useState(null);
    const [filteredData, setFilteredData] = useState(null);


    const form = useFormik({
        initialValues: {
            machineName: "",
            machineNumber: "",
            planName: "",
            planNumber: "",
        },

    });

    const gridForm = useFormik({
        initialValues: {
            gridData: [],
        },

    });


    useEffect(() => {
        if (globalPlant.globalPlant.id) {
            reloadGridData()
        }

    }, [globalPlant, currentUser]);


    useEffect(() => {
        machineMaintenanceService.getMachineMaintenanceCLPPlan(filteredData?.id)
            .then((res) => {
                gridForm.setFieldValue("gridData", res?.data)
            })
    }, [form.values]);



    useEffect(() => {
        form.setFieldValue("machineName", filteredData?.machine_name)
        form.setFieldValue("machineNumber", filteredData?.machine_number)
        form.setFieldValue("planName", filteredData?.plan_name)
        form.setFieldValue("planNumber", filteredData?.plan_number)
    }, [filteredData])


    function reloadGridData() {
        machineMaintenanceService.getMachineMaintenanceCLP(
            currentUser?.client_id,
            globalPlant?.globalPlant?.id,
        )
            .then((res) => {
                const data = res?.data
                const filteredData = data.filter((item) => item.id === componentProps?.id)[0]
                setFilteredData(filteredData)
            });

    }

    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150,
        },

        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },
        {
            field: "q_description",
            headerName: "Description",
            filter: "agTextColumnFilter",
            minWidth: 400,
            cellRenderer: (params) => {
                const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                return wrappedText;
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3' // 
            },
            autoHeight: true,
        },
    ]


    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DGInput
                        label="Machine"
                        id="machineSelected"
                        value={form.values.machineNumber + " / " + form.values.machineName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DGInput
                        label="Plan Name"
                        id="planName"
                        value={form.values.planNumber + " / " + form.values.planName}

                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12} xs={12}
                    justifyContent="flex-end">


                    <CommonGrid

                        columns={columns}
                        rows={gridForm.values.gridData}
                        gridheight={115}
                        needExport={false}


                    />
                </Grid>




            </Grid>

        </>



    )


}