import http from "./httpService";


const createUser = (data) => {
    return http.post("/users", data);
}

const createUserRole = (data) => {
    return http.post('/user-roles', data);
}

const getUsersByClientId = (clientId) => {
    return http.get("/users", {
        params: {
            client_id: `${clientId}`
        }
    })
}

const getActiveUsersByClientId = (clientId) => {
    return http.get("/users", {
        params: {
            client_id: clientId,
            enabled: 1,
        }
    })
}

const getUserApproval = (client_id, current_user) => {
    return http.get("/users-approval", {
        params: {
            client_id: client_id,
            current_user: current_user,
        }
    })
}

const getUserForApproval = (data) => {
    return http.get("/users-approval", { params: { ...data } })
}


const getUserByPhoneNumber = (phoneNumber) => {
    return http.get("/users", {
        params: {
            phone_number: `${phoneNumber}`,
        }
    })
}

const updateUser = (id, data) => {
    return http.patch(`/users/${id}`, data)
}

const UsersInfoCheck = (current_user) => {
    return http.get("/users-info", {
        params: {
            current_user: current_user
        }
    })
}


const UsersScreen = (user_id) => {
    return http.get("/users-info", {
        params: {
            user_id: user_id
        }
    })
}

const getUser = (id) => {
    return http.get(`/users/${id}`);
}

const getClientById = async (clientId) => {
    return http.get(`/clients/${clientId}`);
}

const getUserRole = async (userId) => {
    return http.get('/user-roles', {
        params: {
            user_id: `${userId}`,
        }
    });
}

const getOperatorTable = async (data) => {
    return http.get('/operator-list-table', { params: { ...data } })
}

const createOperator = (data) => {
    return http.post("/operator-list", data);
}


const updateOperatorStatus = async (id, newStatus) => {
    return http.patch(`/operator-list/${id}`, newStatus);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createUser,
    createUserRole,
    getUsersByClientId,
    getUserByPhoneNumber,
    getClientById,
    updateUser,
    getUser,
    getUserRole,
    getActiveUsersByClientId,
    getUserApproval,
    UsersInfoCheck,
    UsersScreen,
    getOperatorTable,
    createOperator,
    updateOperatorStatus,
    getUserForApproval
};