import { Button, Grid, Paper, Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContextProvider";

import CommonGrid from "../../../components/CommonGrid";
import { useFormik } from "formik";
import { DGInput } from "../../../components/shared/DGInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import { object, string, number } from "yup";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import cuttingToolService from "../../../services/cuttingToolService";
import { grid } from "@material-ui/system";


export function CuttingToolAdd({
    Footer = () => null,
    closeForm = () => { },
}) {


    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require("uuid");


    let form = useFormik({
        initialValues: {
            ctool_name: "",
            ctool_cname: "",
            ctool_manufacturer: "",
            ctool_edges: "",
            ctool_reuse: false,
            ctool_life: "",
            ctool_iso: "",
            ctool_grade: "",


        },

        validationSchema: object().shape({
            ctool_name: string().required(),
            ctool_life: number().integer().required(),
        }),

    });



    let gridForm = useFormik({
        initialValues: {
            gridData: [],
        },

        onSubmit: onSave
    })


    const columns = [
        {
            cellRenderer: DeleteRenderer,
            minWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },

        {
            headerName: "Cutting Tool Name",
            field: "ctool_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            field: "ctool_name",
        },
        {
            field: "ctool_cname",
            headerName: "Commercial Name",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "ctool_iso",
            headerName: "ISO Catalog Number",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "ctool_grade",
            headerName: "Grade ",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },


        {
            field: "ctool_manufacturer",
            headerName: "Manufacturer",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "ctool_edges",
            headerName: "Cutting Edges",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        }
        ,
        {
            //  field: "ctool_reuse",
            headerName: "Re-sharpen",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            cellRenderer: (params) =>
                <ResultRenderer
                    value={params?.data?.ctool_reuse}
                />,
        },


        {
            field: "ctool_life",
            headerName: "Target Life",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
    ];


    function onAdd() {
        gridForm.setFieldValue("gridData", [
            ...gridForm.values.gridData,
            form.values,
        ])
        form.resetForm();
    }

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function removeDetailRow(index, rowData) {
        console.log(index, rowData, "index")
        let data = gridForm.values.gridData;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
        gridForm.setFieldValue("gridData", data);
    }


    function onSave() {
        let payload = [];
        gridApi?.forEachNode((node) => {
            payload.push({
                ctool_cname: node?.data?.ctool_cname ?? "",
                ctool_name: node?.data?.ctool_name ?? "",
                ctool_manufacturer: node?.data?.ctool_manufacturer ?? "",
                ctool_edges: node?.data?.ctool_edges ? parseInt(node.data.ctool_edges) : "",
                ctool_reuse: node?.data?.ctool_reuse ?? "",
                ctool_life: node?.data?.ctool_life ?? "",
                ctool_iso: node?.data?.ctool_iso ?? "",
                ctool_grade: node?.data?.ctool_grade ?? "",
                id: uuidv4(),
                created_by: currentUser?.id,
                client_id: currentUser?.client_id,
            });
        });

        console.log(payload, "payload")
        cuttingToolService.createCToolMaster(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    open: true,
                    message: "Added tool to Master Database",
                    type: "success",
                });
            })
            .catch(() => {
                updateAlert({
                    open: true,
                    message: "Failed to add tool to Master Database",
                    type: "error",
                });
            });
    }

    return (
        <div>

            <Grid container spacing={3}>

                <Grid item md={3} sm={3} lg={3}>
                    <DGInput
                        id={"ctool_name"}
                        value={form.values.ctool_name}
                        label="Cutting Tool Name"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

                <Grid item md={4} sm={4} lg={4}>
                    <DGInput
                        id={"ctool_cname"}
                        value={form.values.ctool_cname}
                        label="Cutting Tool Commenrcial Name"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

                <Grid item md={3} sm={3} lg={3}>
                    <DGInput
                        id={"ctool_iso"}
                        value={form.values.ctool_iso}
                        label="ISO Catalog Number"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

                <Grid item md={2} sm={2} lg={2}>
                    <DGInput
                        id={"ctool_grade"}
                        value={form.values.ctool_grade}
                        label="Cutting Tool Grade"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>


                <Grid item md={3} sm={3} lg={3}>
                    <DGInput
                        id={"ctool_life"}
                        value={form.values.ctool_life}
                        label="Estimated Tool Life"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

                <Grid item md={3} sm={3} lg={3} container justify="center" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.values.ctool_reuse}

                                onChange={(e) => {
                                    if (e) {
                                        form.setFieldValue("ctool_reuse", 1);
                                    } else {
                                        form.setFieldValue("ctool_reuse", 0);
                                    }
                                }}
                                name="ctool_reuse"
                                color="primary"
                            />
                        }
                        label="Re-sharpen"
                    />
                </Grid>

                <Grid item md={2} sm={2} lg={2} style={{ marginTop: "5px" }}>
                    <DGInput
                        id={"ctool_edges"}
                        value={form.values.ctool_edges}
                        label="Cutting Tool Edges"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

                <Grid item md={4} sm={4} lg={4} style={{ marginTop: "5px" }}>
                    <DGInput
                        id={"ctool_manufacturer"}
                        value={form.values.ctool_manufacturer}
                        label="Manufacturing Company"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>


            </Grid>

            <Grid item md={12} sm={12} lg={12}
                style={{ padding: '20px' }}>
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={!form.isValid || !form.dirty}
                    onClick={onAdd}
                >
                    ADD
                </Button>

            </Grid>

            <Grid item md={12} sm={12} lg={12} margin-top="20px" >
                <CommonGrid
                    rows={gridForm.values.gridData}
                    columns={columns}
                    needExport={false}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer onSave={gridForm.handleSubmit} />

        </div>

    )


}