import http from "./httpService";

const getStandardPlanPrefill = () => {
  return http.get("/pdi-std-plan-prefill", {});
};

const createStandardPDI = (data) => {
  return http.post("/pdi-std-plan-new", data);
};

// Get Standard Plan
const getPDIStdPlan = (pdi_standard_plan_id) => {
  return http.get("/pdi-std-plan-view", {
    params: {
      pdi_standard_plan_id: pdi_standard_plan_id,
    },
  });
};

// Get Standard List
const getPDIStdListClient = (client_id, plant_id) => {
  return http.get("/pdi-std-plan-list", {
    params: {
      client_id: client_id,
      plant_id: plant_id,
    },
  });
};

const getPDIStdTable = (client_id) => {
  return http.get("/pdi-std-plan-table", {
    params: {
      client_id: client_id,
    },
  });
};

// Create new dispatch Plan

const createNewPDIplan = (data) => {
  return http.post("/pdi-dispatch-plan-new", data);
};

const getClientPDITable = (data) => {
  return http.get("/pdi-dispatch-plan-table", { params: { ...data } });
};

const getPDIPlanDetails = (id) => {
  return http.get("/pdi-dispatch-plan-view", {
    params: { pdi_disptach_plan_id: id },
  });
};

const getPDIPlanDetails2 = (id) => {
  return http.get("/pdi-dispatch-plan-view2", {
    params: { pdi_disptach_plan_id: id },
  });
};

// Create Dispatch Report

const createNewPDIReportStd = (data) => {
  return http.post("/pdi-result-create-std", data);
};

const createNewPDIReportChar = (data) => {
  return http.post("/pdi-result-create-char", data);
};

const createNewPDIReportEvaluate = (data) => {
  return http.post("/pdi-result-evaluate", data);
};

const createNewPDIReportRecord = (data) => {
  return http.post("/pdi-result-record", data);
};

const getPDIReportView = (pdi_result_id) => {
  return http.get("/pdi-result-view", {
    params: { pdi_result_id: pdi_result_id },
  });
};

const getPDIReportTable = (client_id, pdi_result_status) => {
  return http.get("/pdi-result-table", {
    params: {
      client_id: client_id,
      pdi_result_status: pdi_result_status
    }
  });
};

const getPDIResultView = (pdi_result_id) => {
  return http.get("/pdi-result-view", {
    params: { pdi_result_id: pdi_result_id },
  });
};

const getDispatchCharList = (part_id) => {
  return http.get("/pdi-dispatch-char-list", {
    params: { part_id: part_id },
  });
};

const getDispatchCharTable = (character_id) => {
  return http.get("/pdi-result-character", {
    params: { character_id: character_id },
  });
};

const getDispatchCharPlot = (character_id) => {
  return http.get("/pdi-result-char-plot", {
    params: { character_id: character_id },
  });
};

const getDispatchChar = (character_id) => {
  return http.get("/pdi-result-character", {
    params: { character_id: character_id },
  });
};

export default {
  getStandardPlanPrefill,
  createStandardPDI,
  getPDIStdPlan,
  getPDIStdListClient,
  getPDIStdTable,
  createNewPDIplan,
  getClientPDITable,
  getPDIPlanDetails,
  createNewPDIReportStd,
  createNewPDIReportChar,
  createNewPDIReportRecord,
  getPDIReportView,
  getPDIReportTable,
  getPDIResultView,
  getDispatchCharList,
  getPDIPlanDetails2,
  createNewPDIReportEvaluate,
  getDispatchCharTable,
  getDispatchCharPlot,
  getDispatchChar,
};
